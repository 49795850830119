import React, { useState } from "react";
import { Box, Modal, Stack } from "@mui/material";
import Styles from "./Footer.module.css";
import OnestoLogo from "../../assests/onestoLogoWhite.svg";
import LocationIcon from "../../assests/location.svg";
import MailIcon from "../../assests/image 4.svg";
import WhatsappIcon from "../../assests/whatsapp.svg";
import { useNavigate } from "react-router-dom";
import CrossIcon from "../../assests/Cross.svg";
interface NavbarProps {
  scrollToFaqSection: () => void;
  scrollToClientRefSection: () => void;
  scrollToStoryRefSection: () => void;
  scrollToServiceRefSection: () => void;
  scrollToProductRefSection: () => void;
}
interface product {
  title: string;
  modalData: {
    content: string;
    points: string[];
  };
}
const ProductData1 = [
  {
    title: "Body Care",
    modalData: {
      content:
        "We specialize in manufacturing a wide range of body care products designed to nourish, cleanse, and refresh the skin. Our formulations are developed using honest, transparent, and science-backed ingredients, ensuring they deliver effective results.<br/>From <b>Body Lotions to Massage Oils, Body Scrubs, Body Butters, and Skin Roll Ons</b>, we create high-quality products that cater to the needs of our partners and their consumers.",
      points: [
        "Free from sulphates & parabens",
        "Clinically Tested",
        "Premium Quality Ingredients",
      ],
    },
  },
  {
    title: "Face Care",
    modalData: {
      content:
        "At Onesto Labs, we focus on manufacturing face care products designed to enhance skincare routines with scientifically backed formulations. Our commitment is to develop products that deliver effective, long-lasting results while ensuring safety and efficacy by avoiding harmful chemicals.<br/>We specialize in creating a range of <b>face care solutions, including Face Cream, Face Serum, Face Wash (Foam, Cream, Gel), Night Cream, Toner, Lip Scrub, and Lip Mask,</b> tailored as per our partner’s needs.",
      points: [
        "Clean Ingredients ",
        "Free from sulphates & parabens",
        "No harmful chemicals",
      ],
    },
  },

  {
    title: "Sun Care",
    modalData: {
      content:
        "Our expert formulations combine essential ingredients and high-performance SPF to effectively protect the skin from harmful UV rays while providing care and nourishment. We manufacture a wide range of sun care solutions, including <b>Face Sunscreens, Body Sunscreens, and Sunscreen Sticks</b>, all designed to meet diverse client specifications.<br/>Our manufactured sunscreens excel at: ",
      points: [
        "SPF Metrics",
        "Leaving No White Cast/ Fast Absorption",
        "Photoageing & Sun Damage Prevention",
        "Safety Parameters (Skin Friendly & Irritation-Free)",
      ],
    },
  },
  {
    title: "Hair Care",
    modalData: {
      content:
        "As a dedicated manufacturer, we produce a comprehensive selection of hair care products, including <b>Shampoo, Conditioner, Mask, Spray, Oil, Serum, Wax</b>, and many more. Our formulations are developed with precision, using proven ingredients to ensure optimal performance in cleansing, conditioning, and styling. We focus on delivering products that meet the highest standards, providing our clients with trusted solutions for their hair care lines.",
      points: [
        "Free from sulphates & parabens",
        "Clinically Texted",
        "Premium Quality Ingredients",
      ],
    },
  },
];

const ProductData2 = [
  {
    title: "Baby Care",
    modalData: {
      content:
        "We specialize in producing high-quality baby care products that meet the strictest safety standards. Our range is carefully crafted with gentle, nourishing formulations to cater to the sensitive needs of a baby’s delicate skin. From <b>Shampoo and Hair Oil to Massage Oil and Rash Cream</b>, we offer a full line of baby care essentials, designed to provide comfort and care, ensuring the highest level of protection for your brand's youngest customers.<br/>What Makes Our Baby Care Products Different?",
      points: [
        "Free from Sulphates, Parabens & Fragrances",
        "Tear-Free & Hypoallergenic",
        "Gentle for daily use",
        "Balanced pH",
      ],
    },
  },
  {
    title: "Fragrance",
    modalData: {
      content:
        "At Onesto Labs, we specialize in creating exceptional fragrances tailored specifically for your brand. Our focus is on crafting scents that enhance your products and resonate with your target audience. <br/>We use high-quality ingredients to ensure that your brand offers delightful and long-lasting fragrances. Our range includes custom <b>Perfumes, and Body Sprays</b>, all designed to elevate the consumer experience.",
      points: [
        "Long-Lasting Formulas ",
        "Sustainable Sourcing",
        "Ethical Production",
      ],
    },
  },
  {
    title: "hygiene",
    modalData: {
      content:
        "We manufacture high-quality hygiene products that prioritize cleanliness and safety in everyday life. Our formulations are designed to effectively cleanse while being gentle on the skin. <br/>With our focus on quality, we empower your brand to provide trustworthy hygiene solutions. Our product range includes <b>Hand Wash, Body Wash, Intimate Care products Antibacterial Gel</b>, and more, all crafted to meet the needs of your customers. ",
      points: [
        "Dermatologist Tested",
        "Superior Formulation",
        " Long-Lasting Freshness",
      ],
    },
  },
  {
    title: "Pet Care",
    modalData: {
      content:
        "At Onesto Labs, we take pride in crafting high-quality pet care products that cater to the unique needs of your brand. Our formulations are designed to be safe and effective, ensuring the pets receive the best care possible. <br/>With a commitment to quality, we help your brand offer reliable solutions for pet grooming and hygiene. We manufacture a wide range of pet care products including <b>Shampoo, Conditioner, Grooming Wipes<b/>, and more, all tailored to keep pets clean and happy.",
      points: [
        "Cruelty-Free",
        "Veterinarian Approved",
        "Natural & Safe Ingredients",
      ],
    },
  },
];

const Footer: React.FC<NavbarProps> = ({
  scrollToFaqSection,
  scrollToClientRefSection,
  scrollToStoryRefSection,
  scrollToServiceRefSection,
  scrollToProductRefSection,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = useState<product>({
    title: "",
    modalData: {
      content: "",
      points: [],
    },
  });

  const navOptions = [
    {
      title: "Our Story",
      action: scrollToStoryRefSection,
    },
    {
      title: "Our Services",
      action: scrollToServiceRefSection,
    },
    {
      title: "Our Products",
      action: scrollToProductRefSection,
    },
    {
      title: "Our Cliente",
      action: scrollToClientRefSection,
    },
    {
      title: "FAQs",
      action: scrollToFaqSection,
    },
  ];
  const handleOpenModal = (product: product) => {
    setModalData(product);
    handleOpen();
  };
  const handleGetDirections = (address: any) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };
  const handleMailtoClick = (mail: string) => {
    window.location.href = `mailto:${mail}`;
    console.log("Opening email client...");
  };
  return (
    <>
      <Box className={Styles.footer_container}>
        <Stack className={Styles.footer_container_top}>
          <Stack className={Styles.footer_container_socialmedia}>
            <Stack
              className={Styles.footer_container_companylogo}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/footer%2Fonestolabs_logo_footer.png?alt=media&token=8e53f460-c30c-4953-af76-42f7d63be9be"} alt="OnestoLogo" />{" "}
            </Stack>

            <Stack className={Styles.footer_container_socialmedia_text}>
            Your trusted partner for R&D & Manufacturing
            </Stack>

            <Stack className={Styles.footer_container_socialmedia_icon}>
              <Stack
                className={Styles.socialmedia_icon}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleMailtoClick("connect@onestolabs.com");
                }}
              >
                <img src={MailIcon} alt="mail" />
              </Stack>
              <Stack
                className={Styles.socialmedia_icon}
                component="a"
                href="https://api.whatsapp.com/send?phone=919654545148&amp;text=Hi" // 91 is the country code for India
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              >
                <img src={WhatsappIcon} alt="whatsappp" />
              </Stack>
              <Stack
                className={Styles.socialmedia_icon}
                sx={{
                  textTransform: "capitalize !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleGetDirections(
                    "Plot 309 Sec 6, IMT Manesar, Gurgaon, 122052"
                  );
                }}
              >
                <img src={LocationIcon} alt="location" />
              </Stack>
            </Stack>
          </Stack>

          {/* <Stack className={Styles.footer_container_products_options}>
            <Stack className={Styles.spacing_between}>
              <Stack
                className={Styles.menu_options_head}
                sx={{ marginBottom: { xs: "", md: "10px" } }}
              >
                Our <span>company</span>
              </Stack>
              <Stack className={Styles.spacing_between_options}>
                {navOptions.map((option, index) => (
                  <Stack
                    className={Styles.menu_option}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      option.action?.();
                    }}
                  >
                    {option.title}
                  </Stack>
                ))}
                <Stack
                  className={Styles.menu_option}
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/contactus");
                  }}
                >
                  Contact Us
                </Stack>
              </Stack>
            </Stack>
            <Stack className={Styles.spacing_between}>
              <Stack
                className={Styles.menu_options_head}
                sx={{ marginBottom: { xs: "", md: "10px" } }}
              >
                Our <span>Products</span>
              </Stack>
              <Stack className={Styles.spacing_between_options_column}>
                <Stack className={Styles.spacing_between_options}>
                  {ProductData1.map((product, index) => (
                    <Stack
                      className={Styles.menu_option}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOpenModal(product);
                      }}
                    >
                      {product.title}
                    </Stack>
                  ))}
                </Stack>
                <Stack className={Styles.spacing_between_options}>
                  {ProductData2.map((product, index) => (
                    <Stack
                      className={Styles.menu_option}
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        handleOpenModal(product);
                      }}
                    >
                      {product.title}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack> */}
        </Stack>

        <Stack className={Styles.footer_container_bottom}>
          <Stack className={Styles.footer_container_copyright}>
            © 2024 Onestolabs. All rights reserved.
          </Stack>

          <Stack className={Styles.footer_container_copyright_sub}>
            <Stack className={Styles.sub_tex} sx={{cursor:"pointer"}}
            onClick={()=>{
              navigate("/privacy");
            }}>Privacy policy</Stack>
            <Stack className={Styles.sub_tex}
            sx={{cursor:"pointer"}}
            onClick={()=>{
              navigate("/terms");
            }}
            >Terms of Services</Stack>
          </Stack>
        </Stack>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {modalData && (
          <Box
            className={Styles.Modal_container}
            sx={{
              bgcolor: "background.paper",
            }}
          >
            <Stack className={Styles.Modal_head}>
              <Stack
                className={Styles.Modal_title}
                dangerouslySetInnerHTML={{
                  __html: modalData?.title,
                }}
              />

              <Stack
                className={Styles.modal_close}
                onClick={() => {
                  handleClose();
                }}
              >
                <img src={CrossIcon} />
              </Stack>
            </Stack>

            <Stack className={Styles.Modal_content}>
              {" "}
              <Stack className={Styles.Modal_content_layout}>
                <Stack
                  className={Styles.Modal_content_info}
                  dangerouslySetInnerHTML={{
                    __html: modalData.modalData.content,
                  }}
                />
                <Stack className={Styles.Modal_content_points}>
                  {modalData.modalData.points.map((point, index) => (
                    <Stack key={index} className={Styles.point_item}>
                      {"- "}
                      {point}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
            <Stack
              className={Styles.bot_content_btn}
              onClick={() => {
                navigate("/contactus");
                handleClose();
              }}
            >
              Lets Connect
            </Stack>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default Footer;
