import { Box, Modal, Stack } from "@mui/material";
import React, { useState } from "react";
import Styles from "./ServiceSection.module.css";
import CrossIcon from "./../../../assests/Cross.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface ReadMore {
  title: string;
  Content: string;
  points: string[];
  subContent: string;
}

const ServiceSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };


  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.2,    // Adjust threshold for when the animation should trigger
  });

  const [modalData, setModalData] = useState<ReadMore>({
    title: "",
    Content: "",
    points: [],
    subContent: "",
  });
  const handleReadMore = (content: any) => {
    handleOpen();
    console.log(content);
    setModalData(content);
  };

  const serviceData = [
    {
      value: 1,
      img1: "https://cdn.shopify.com/s/files/1/0039/6073/3763/files/onestolabs_1.1.png?v=1732209229",
      img2: "https://cdn.shopify.com/s/files/1/0039/6073/3763/files/onestolabs_1.2.png?v=1732209227",
      content:
        "The First Step to Bringing Your Product to Life...",
      readMore: {
        title: "ideation",
        Content:
          "<b>The First Step to Bringing Your Product to Life</b><br/>The ideation process begins by transforming your product vision into a reality. We focus on three key elements to ensure your product's foundation is strong:",
        points: [
          "Defining and conceptualizing a unique product based on your needs.",
          " Identifying and sourcing the finest ingredients that align with your product’s goals and budget.",
          "Exploring the approach towards the manufacturing of the ideated product.",
        ],
        subContent: "Ready to start your product journey? ",
      },
    },
    {
      value: 2,
      img2: "https://cdn.shopify.com/s/files/1/0039/6073/3763/files/onestolabs_2.1.png?v=1732209226",
      img1: "https://cdn.shopify.com/s/files/1/0039/6073/3763/files/onestolabs_2.2.png?v=1732209229",
      content:
        "The Foundation for Building a Successful Product... ",
      readMore: {
        title: "research & Development",
        Content:
          "<b>The Foundation for Building a Successful Product</b><br/> Our Research & Development team, with over 100 years of combined experience from leading FMCG brands like Johnson & Johnson and Revlon, brings a rich blend of both national and international expertise to every project. Using advanced technology—stability chambers, viscometers, and pH meters—we conduct rigorous testing to ensure unmatched quality.",
        points: [
          "Developing efficient and innovative solutions designed for quick market entry.",
          "Creating bespoke formulations tailored to meet your brand's specific requirements.",
          " Enhancing existing products by improving quality, performance, and appeal.",
          "Offering specialized research services to support product development and innovation.",
        ],
        subContent: "Looking to develop a breakthrough product?  ",
      },
    },
    {
      value: 3,
      img2: "https://cdn.shopify.com/s/files/1/0039/6073/3763/files/onestolabs_3.1.png?v=1732209226",
      img1: "https://cdn.shopify.com/s/files/1/0039/6073/3763/files/onestolabs_3.2.png?v=1732209229",
      content:
        "The Secret to Your Product's Success...",
      readMore: {
        title: "testing",
        Content:
          "<b>The Secret to Your Product's Success</b><br/>Once product development or customization is complete, we rigorously test for safety and key claims validation. Our facility is equipped with stability chambers, Mexameter, Corneometer, and other advanced tools to assess performance and stability.",
        subContent:
          "Only after passing these critical stages—ensuring both effectiveness and quality—will your product be approved for release, meeting the highest industry standards.<br/><br/>Ready to ensure your product's success?"      },
    },
    {
      value: 4,
      img1: "https://cdn.shopify.com/s/files/1/0039/6073/3763/files/onestolabs_4.1.png?v=1732209229",
      img2: "https://cdn.shopify.com/s/files/1/0039/6073/3763/files/onestolabs_4.2.png?v=1732209227",
      content:
        "Bringing Your Vision to Life with Precision...",
      readMore: {
        title: "Manufacturing",
        Content:
          "<b>Bringing Your Vision to Life with Precision</b><br/>Our 44,000 sq. ft. manufacturing facility is meticulously designed to deliver the highest standards in product development and safety. From initial concept to final output, our process emphasizes precision, quality, and reliability at every step.<br/><br/>With a monthly capacity of over 1 million products and a diverse team of 150+ experts, we specialize in bringing consistent, high-quality solutions to market with efficiency and care. ",
        points: [
          "for Innovation",
          "Launched ",
          "Launched for Diverse Needs",
          "Top-Tier",
          "Monthly Production Capacity",
        ],
        subContent:"Ready to partner for excellence? "
      },
    },
  ];

  const currentData = serviceData.find((data) => data.value === value);

  return (
    <>
      <Box className={Styles.service_container} ref={ref}>
        <motion.div className={Styles.service_container_head}
               initial={{ opacity: 0, y: 10 }} 
               animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
               exit={{ opacity: 0 }} 
               transition={{
                 duration: 1, 
                 ease: "easeInOut", 
               }}
             >
          We are Your Brand’s <span>Extended Support</span>
        </motion.div>
        <Stack className={Styles.service_container_tabs}>
          <motion.div className={Styles.tabs}
                initial={{ opacity: 0, y: 10 }} 
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
                exit={{ opacity: 0 }} 
                transition={{
                  duration: 1, 
                  ease: "easeInOut", 
                }}
                >
            {["Ideation", "R & D", "Testing", "Manufacturing"].map(
              (label, index) => (
                <Stack
                  key={index}
                  className={Styles.tabs_options}
                  sx={{
                    color: value === index + 1 ? "#002D62 !important" : " #000",
                    borderBottom:
                      value === index + 1
                        ? "2px solid #002D62"
                        : "none",
                    fontWeight:
                      value === index + 1 ? "bold !important" : "normal",
                  }}
                  onClick={() => handleChange(index + 1)}
                >
                  {label}
                </Stack>
              )
            )}
          </motion.div>

          {/* Content  */}
          <Stack className={Styles.tab_Content}>
            {value && (
              <>
                {currentData && (
                  <Stack
                    className={Styles.Service_Content}
                    sx={{
                      display: "flex",
                      flexDirection:
                        value === 1
                          ? "row"
                          : value === 2
                          ? "row-reverse"
                          : value === 3
                          ? "row-reverse"
                          : "row",
                    }}
                  >
                    <Stack
                      className={Styles.Service_Content_poster}
                      sx={{
                        borderRight: {
                          xs: "none",
                          md:
                            value == 1 || value == 4
                              ? "10px solid #fff"
                              : "none",
                        },
                        borderLeft: {
                          xs: "none",
                          md:
                            value == 3 || value == 2
                              ? "10px solid #fff"
                              : "none",
                        },
                        display: { xs: "none", md: "block" },
                        width: "70%",
                      }}
                    >
                      {/* <img
                        src={currentData.img1}
                        style={{ width: "100%", height: "auto" }}
                      /> */}
                     {value ==1 ? <img
                        src={currentData.img1}
                        style={{ width: "100%", height: "auto" }}
                      /> 
                      :
                      <img 
                      alt="service imag"
                      src={currentData.img1}
                        style={{ width: "100%", height: "auto" }}/>
                        }
                    </Stack>
                    <Stack
                      className={Styles.Service_Content_side}
                      sx={{
                        display: "flex",
                        flexDirection: {
                          xs: value == 3 || value == 4 ? "column" : "column",
                          md:
                            value === 1
                              ? "column"
                              : value === 2
                              ? "column"
                              : value === 3
                              ? "column-reverse"
                              : "column-reverse",
                        },
                      }}
                    >
                      <Stack
                        className={Styles.Service_Content_side_poster}
                        sx={{
                          borderBottom: {
                            xs: "none",
                            md:
                              value == 1 || value == 2
                                ? "10px solid #fff"
                                : "none",
                          },
                          borderTop: {
                            xs: "none",
                            md:
                              value == 3 || value == 4
                                ? "10px solid #fff"
                                : "none",
                          },
                          
                        }}
                    
                       
                      >
                        {/* <img src={currentData.img2} /> */}
                      { value ==1 ? <img src={currentData.img2} /> 
                      :
                       <img 
                      alt="service imag"
                      src={currentData.img2}
                        />}
                      </Stack>

                      <Stack
                        className={` ${
                          value == 1 || value == 2
                            ? Styles.Service_Content_side_content
                            : Styles.Service_Content_side_content2
                        } `}
                      >
                        <motion.div className={Styles.side_content} 
                           initial={{ opacity: 0, y: 10 }} 
                           animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
                           exit={{ opacity: 0 }} 
                           transition={{
                             duration: 1, 
                             ease: "easeInOut", 
                           }}
                           >
                          {currentData.content}
                        </motion.div>
                        <motion.div
                          className={Styles.side_content_btn}
                          onClick={() => {
                            handleReadMore(currentData.readMore);
                          }}
                        
                          initial={{ opacity: 0, y: 10 }} 
                          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
                          exit={{ opacity: 0 }} 
                          transition={{
                            duration: 1, 
                            ease: "easeInOut", 
                          }}
                          >
                          Read More
                        </motion.div>
                      </Stack>
                    </Stack>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Stack>
      </Box>

      {/* Read More Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          className={Styles.Modal_container}
          sx={{
            bgcolor: "background.paper",
          }}
        >
          <Stack className={Styles.Modal_head}>
            <Stack className={Styles.Modal_title}>
              {modalData && modalData?.title}
            </Stack>
            <Stack
              className={Styles.modal_close}
              onClick={() => {
                handleClose();
              }}
            >
              <img src={CrossIcon} />
            </Stack>
          </Stack>

          <Stack className={Styles.Modal_content}>
            {" "}
            <Stack className={Styles.Modal_content_layout}>
              <Stack
                className={Styles.Modal_content_info}
                dangerouslySetInnerHTML={{
                  __html: modalData.Content,
                }}
              />
              {/* Ideation Point */}

              {value === 1 && (
                <Stack className={Styles.ideation_content_points}>
                  <Stack className={Styles.ideation_points}>
                    <span> {"• "} Product: </span> {modalData.points[0]}
                  </Stack>
                  <Stack className={Styles.ideation_points}>
                    {" "}
                    <span> {"• "}Procurement: </span>
                    {modalData.points[1]}
                  </Stack>
                  <Stack className={Styles.ideation_points}>
                    {" "}
                    <span> {"• "} Performance: </span>
                    {modalData.points[2]}
                  </Stack>
                </Stack>
              )}

              {/* Rnd Point */}
              {value === 2 && (
                <Stack className={Styles.ideation_content_points}>
                  Our R&D approach:
                  <Stack className={Styles.ideation_points}>
                    <span> {"• "} Ready-to-Market Products: </span> {modalData.points[0]}
                  </Stack>
                  <Stack className={Styles.ideation_points}>
                    {" "}
                    <span> {"• "}Exclusivity & Customization: </span>
                    {modalData.points[1]}
                  </Stack>
                  <Stack className={Styles.ideation_points}>
                    {" "}
                    <span> {"• "} Value Addition: </span>
                    {modalData.points[2]}
                  </Stack>
                  <Stack className={Styles.ideation_points}>
                    {" "}
                    <span> {"• "} Contract Research: </span>
                    {modalData.points[3]}
                  </Stack>
                </Stack>
              )}

              {value === 4 && (
                <Stack className={Styles.ideation_content_points}>
                  <Stack className={Styles.ideation_points}>
                  {"• "} <span style={{fontWeight:"bold"}}> Advanced R&D Lab </span> {modalData.points[0]}
                  </Stack>
                  <Stack className={Styles.ideation_points}>
                    {" "}{"• "}
                    <span  style={{fontWeight:"bold"}}> 150+ SKUs </span>
                    {modalData.points[1]}
                  </Stack>
                  <Stack className={Styles.ideation_points}>
                    {" "}{"• "} 
                    <span> Performance: </span>
                    {modalData.points[2]}
                  </Stack>

                  <Stack className={Styles.ideation_points}>
                    {" "}
                    {"• "} {modalData.points[3]} <span> Anti-Contamination Systems </span>
                   
                  </Stack>
                  <Stack className={Styles.ideation_points}>
                  {"• "} 
                    <span> 200+ Tons </span>
                    {modalData.points[4]}
                  </Stack>
                  
                  
                </Stack>
              )}
              <Stack
                className={Styles.Modal_content_info}
                dangerouslySetInnerHTML={{
                  __html: modalData.subContent,
                }}
              />
              {/* Rnd Points */}
            </Stack>
          </Stack>
          <Stack
            className={Styles.bot_content_btn}
            onClick={() => {
              navigate("/contactus");
            }}
          >
            Partner with Us
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default ServiceSection;
