import React, { useEffect, useRef, useState } from "react";
import Styles from "./VisionSection.module.css";
import { Box, Stack } from "@mui/material";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const VisionSection = () => {
  const MissionImage1 = "https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-vision%2FSafety_v1.1.mp4?alt=media&token=935d96ee-7b8b-4e1b-851f-8ac75c74412b";
  const MissionImage2 = "https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-vision%2FQuality.mp4?alt=media&token=9540975e-a4a4-442f-aac4-e5d0a969ddde";
  const MissionImage3 = "https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-vision%2FEfficacy.mp4?alt=media&token=53c434f9-d6ce-4056-97e2-46a27fbe2f4f";

  const [value, setValue] = useState(1);
  const videoRef = useRef<HTMLVideoElement>(null);

  const visionData = [
    {
      value: 1,
      title: "We prioritise <span>safety</span>",
      content:
        "We use clean ingredients to ensure safety and minimize side effects, while also maintaining a long shelf life for effectiveness.",
    },
    {
      value: 2,
      title: "We deliver <span>quality</span>",
      content:
        "With a state-of-the-art facility and experienced scientists, we prioritize delivering quality to our customers.",
    },
    {
      value: 3,
      title: "we formulate for <span>efficacy</span>",
      content:
        "Our ingredients are sourced from more than 20 countries globally, with a focus on meticulous formulation and rigorous testing to deliver effective results.",
    },
  ];

  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.2, 
  });

  const handleChangePoster = (value: any) => {
    setValue(value);
  };

  return (
    <>
      <Box className={Styles.vision_container}>
        <motion.div className={Styles.vision_container_title}
             initial={{ opacity: 0, y: 10 }} 
             animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
             exit={{ opacity: 0 }} 
             transition={{
               duration: 1, 
               ease: "easeInOut", 
             }}
         >
          Your vision, <span>Our Promise</span>
        </motion.div >
        <Box className={Styles.vision_container_content} ref={ref}>
          <Stack className={Styles.left_content} height="auto">
            <Stack className={Styles.left_video} height="100%" width="100%">
              <video
                
                  ref={videoRef}
                  src={value === 1 ? MissionImage1 : value === 2 ? MissionImage2 : MissionImage3}
                  muted
                  loop
                  autoPlay
                  playsInline
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onCanPlay={() => console.log("Video loaded successfully")}
              />
              <Stack className={Styles.poster_name}>
                {/* <Stack
                  className={Styles.poster_name_text}
                  sx={{ display: { xs: "none", md: "flex" } }}
                >
                  Your Vision is <span>Our Mission</span>
                </Stack> */}
              </Stack> 
            </Stack>
          </Stack>

          <Stack className={Styles.right_content}>
            {visionData.map((item, index) => (
              <Stack
                key={item.value}
                className={Styles.content}
                sx={{
                  borderLeft:
                    value === item.value
                      ? "6px solid #7CF169"
                      : "6px solid #d9d9d9",
                  transition: "border-left 0.3s ease",
                }}
              >
                <Stack
                  className={Styles.single_content}
                  sx={{
                    borderBottom: index < 2 ? "2px solid #fff" : "none",
                    opacity: value !== item.value ? "0.3" : "1",
                    transition: "opacity 0.3s ease",
                  }}
                  onClick={() => handleChangePoster(item.value)}
                >
                  <motion.div
                    className={Styles.content_title}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                    initial={{ opacity: 0, y: 10 }} 
                    animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
                    exit={{ opacity: 0 }} 
                    transition={{
                      duration: 1, 
                      ease: "easeInOut", 
                    }}
                  ></motion.div>
                  <motion.div className={Styles.content_info}
                       initial={{ opacity: 0, y: 10 }} 
                       animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
                       exit={{ opacity: 0 }} 
                       transition={{
                         duration: 1, 
                         ease: "easeInOut", 
                       }}
                   >{item.content}</motion.div>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default VisionSection;
