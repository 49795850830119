import React, { useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Styles from "./FaqAccordion.module.css";
import { Box, Grid, Stack } from "@mui/material";
import DownArrow from "./../../../assests/DownArrow.svg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface AccordionProps {
  title: string;
  content: string;
}
const Accordion: React.FC<AccordionProps> = ({ title, content }) => {
  const [active, setActive] = useState(false);
  const contents = useRef(null);
  const [height, setHeight] = useState("0px");
  const [arrowRotation, setArrowRotation] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.4, // Adjust threshold for when the animation should trigger
  });

  const toggleAccordion = () => {
    setActive(!active);
    //   setHeight( active ? "0px" : `${ contents?.current?.scrollHeight }px` );
    setHeight(active ? "0px" : `150px`);
    setArrowRotation(active ? 0 : 180);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <div className={Styles.accordion__section} ref={ref}>
        <div className={`${Styles.accordion} ${active ? "active" : ""}`}>
          <motion.div onClick={toggleAccordion} className={Styles.accordion__title}
            initial={{ opacity: 0, y: 10 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 1,
              ease: "easeInOut",
            }}>
            {title}
          </motion.div>
          <Stack
            className={Styles.accordion__section_down}
            onClick={toggleAccordion}
            style={{ padding: "0 6px 4px 0" }}
          >
            {/* <ArrowDropDownIcon /> */}
            <img
              src={DownArrow}
              alt="downArrow"
              style={{
                transform: `rotate(${arrowRotation}deg)`,
                transition: "transform 0.3s ease",
              }}
            />
          </Stack>
        </div>
        <div
          ref={contents}
          style={{ maxHeight: `${height}` }}
          className={Styles.accordion__content}
        >
          <div
            className={Styles.accordion__text}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </Box>
  );
};

export default Accordion;
