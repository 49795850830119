import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import Styles from "./OurStorySection.module.css";
import ContactUsModal from "../ContactModal/ContactUsModal";
import RnD from "./../../../assests/rnd.svg";
import Manufacturing from "./../../../assests/manufacturing.svg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import OurStorybottom from "./OurStoryBottom";

const OurStorySection = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.2, // Adjust threshold for when the animation should trigger
  });

  return (
    <>
      <Box className={Styles.our_story_container} ref={ref}>
        <motion.div 
          className={Styles.our_story_main_content_title}
          initial={{ opacity: 0, y: 10 }} // Start hidden and slightly below
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} // Fade in and move up when in view
          exit={{ opacity: 0 }} // Fade out when exiting
          transition={{
            duration: 1, // Duration of the animation
            ease: "easeInOut", // Smooth fade-in and fade-out
          }}
        >
          Consider Us as <span>Your Trusted Product Partner</span>
        </motion.div>
        <Stack className={Styles.our_story_container_top}>
          {/* <Stack className={Styles.top_img_container}></Stack>{" "} */}
          <Stack className={Styles.left_content} height="auto">
            <Stack className={Styles.left_video} height="100%" width="100%"  ref={ref}>
              {/* <img
                src={rectangle}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              /> */}
              <picture>
                <source
                  srcSet="https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our%20story%20section%2Fonestolabs_cofounder_desktop.png?alt=media&token=5a961123-bf98-4832-abc2-e605f9b20801"
                  media="(min-width: 768px)"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our%20story%20section%2Fonestolabs_cofounder_mobile.png?alt=media&token=205d356c-a2db-499d-b148-408986686dad"
                  alt="Poster"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </picture>
              {/* <Stack className={Styles.poster_name}> */}
              {/* <Stack className={Styles.poster_name_text}>
                  "Onesto Lab is built on the foundation of{" "}
                  <p>honest research and exceptional manufacturing</p>. Our
                  Commitiment is to make a positive impact through"every
                  formulation we created."
                  <span style={{ color: "#7cf169" }}> - Vimal Bhola </span>
                  <span style={{ marginTop: "20px" }}>
                    Director of Product Developement <br />
                    (Chief Scientist)
                  </span>
                </Stack> */}
              {/* <img src={PosterTag} />
              </Stack> */}
            </Stack>
          </Stack>

          <Stack className={Styles.top_content}>
            <motion.div
              className={Styles.top_content_layout1}
              initial={{ opacity: 0, y: 10 }} // Start hidden and slightly below
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} // Fade in and move up when in view
              exit={{ opacity: 0 }} // Fade out when exiting
              transition={{
                duration: 1.5, // Duration of the animation
                ease: "easeInOut", // Smooth fade-in and fade-out
              }}
            >
              <Stack className={Styles.top_content_layout_head}>
                {" "}
                <Stack className={Styles.top_content_layout_head_img}>
                  <img src={RnD} />
                </Stack>
                <Stack className={Styles.top_content_layout_head_title}>
                  {" "}
                  Research & <span>Development</span>
                </Stack>
              </Stack>
              <Stack className={Styles.top_content_layout_info}>
                Our scientists conduct thorough testing and analysis in the lab
                to create impactful products. We use globally sourced
                ingredients, all backed by scientific research. This ensures
                each product is safe and effective.
              </Stack>
            </motion.div>{" "}
            <motion.div
             
              className={Styles.top_content_layout}
              initial={{ opacity: 0, y: 10 }} // Start hidden and slightly below
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} // Fade in and move up when in view
              exit={{ opacity: 0 }} // Fade out when exiting
              transition={{
                duration: 1.5, // Duration of the animation
                ease: "easeInOut", // Smooth fade-in and fade-out
              }}
            >
              <Stack className={Styles.top_content_layout_head}>
                {" "}
                <Stack className={Styles.top_content_layout_head_img}>
                  <img src={Manufacturing} />{" "}
                </Stack>
                <Stack className={Styles.top_content_layout_head_title}>
                  {" "}
                  Manufacturing <span>Unit</span>
                </Stack>
              </Stack>
              <Stack className={Styles.top_content_layout_info}>
                We have a state-of-art manufacturing setup that allows us to
                closely control quality and safety at every stage of product
                development. This ensures that every product meets our high
                standards before it reaches the market.
              </Stack>
            </motion.div>{" "}
          </Stack>
        </Stack>
      <OurStorybottom/>
      
      </Box>
      <ContactUsModal open={open} handleClose={handleClose} />
    </>
  );
};

export default OurStorySection;
