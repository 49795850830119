import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Styles from "./Formulation.module.css";
import ContactUsModal from "../ContactModal/ContactUsModal";
import FormulationAccordion from "./FormulationAccordion";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";


const FormulationSummary = () => {
    const [open, setOpen] = useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [activeIndex, setActiveIndex] = useState<number | null>(1);
  
    const { ref, inView } = useInView({
      triggerOnce: true, // Animation triggers only once
      threshold: 0.4, // Adjust threshold for when the animation should trigger
    });
  
    const toggleAccordion = (index: number) => {
      if (activeIndex === index) {
        setActiveIndex(null);
      } else {
        setActiveIndex(index);
      }
    };
  
    useEffect(() => {
      if (activeIndex === null) {
        setActiveIndex(1);
      }
    }, [activeIndex]); 

    return (<>
      <Stack
          className={Styles.formulation_section_part3}
          sx={{ marginTop: { xs: "none", md: "20px" } }}
          ref={ref}
        >
          <Stack className={Styles.formulation_section_part3_content}>
            <motion.div
              className={Styles.formulation_section_part3_head}
              initial={{ opacity: 0, y: 10 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 1.5,
                ease: "easeInOut",
              }}
            >
              We Can be Your{" "}
              <span className={Styles.formulation_section_part3_head_color}>
                Extended Team for Success
              </span>
            </motion.div>
            <motion.div
              className={Styles.formulation_section_part3_info}
              initial={{ opacity: 0, y: 10 }} 
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
              exit={{ opacity: 0 }} 
              transition={{
                duration: 1.5, 
                ease: "easeInOut", 
              }}
            >
              We’re dedicated to your success through clean, honest, and
              innovative formulations. We transform your ideas into products
              that people will love. By combining your marketing skills with our
              manufacturing expertise, let us create standout products together.
              More than just a partner, we are an extension of your team,
              committed to bringing your vision to life.
            </motion.div>
            <motion.div
              className={Styles.formulation_section_part3_content_contact_btn}
              initial={{ opacity: 0, y: 10 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 1.5,
                ease: "easeInOut",
              }}
              onClick={() => {
                handleOpen();
              }}
            >
              <span>Get in Touch</span>
            </motion.div>
          </Stack>
          {/* <Stack className={Styles.formulation_section_part3_img}>
            <Stack className={Styles.part3_img}>
              <img src={CRcon} />
         
            </Stack>
            <Stack className={Styles.part3_img}>
          
              <img src={CruelyIcon} />
            </Stack>
            <Stack className={Styles.part3_img}>
            
              <img src={GMPIcon} />
            </Stack>
            <Stack className={Styles.part3_img}>
           
              <img src={IfraIcon} />
            </Stack>
          </Stack> */}
        </Stack>
        <ContactUsModal open={open} handleClose={handleClose} />
    </>)}


export default FormulationSummary;
