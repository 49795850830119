import React, { useEffect, useRef, useState } from "react";
import Styles from "./Contact.module.css";
import RIbbonIcon from "./../../assests/Ribbon.svg";
import WhiteHouse from "./../../assests/whiteHouse.svg";
import HouseIcon from "./../../assests/House.svg";
import MailIcon from "./../../assests/MailBlue.svg";
import WhatsappIcon from "./../../assests/WhatsappBlue.svg";
import LocationIcon from "./../../assests/LocationBlue.svg";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import indianStates from "./../../Indian_states.json";
import { toast, ToastContainer } from "react-toastify";
import { SnackbarProvider, useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface State {
  name: string;
  districts: string[];
}

const Contact = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.2,    // Adjust threshold for when the animation should trigger
  });

  const handleGetDirections = (address: any) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };
  const handleMailtoClick = (mail: string) => {
    window.location.href = `mailto:${mail}`;
    console.log("Opening email client...");
  };

  const { enqueueSnackbar } = useSnackbar();
  const toasterSuccess = () => {
    enqueueSnackbar("Your mail has been sent successfully to OnestoLabs.", {
      variant: "success",
    });
  };
  const form = useRef<HTMLFormElement>(null); // Create a ref for the form
  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedDistrict, setSelectedDistrict] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState({
    userName: "",
    userEmail: "",
    userPhone: "",
    // selectedState: "",
    // selectedDistrict: "",
    message: "",
  });

  const validateFields = () => {
    let valid = true;
    const newErrors = {
      userName: "",
      userEmail: "",
      userPhone: "",
      // selectedState: "",
      // selectedDistrict: "",
      message: "",
    };

    if (!userName.trim()) {
      newErrors.userName = "Name is required.";
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userEmail.trim()) {
      newErrors.userEmail = "Email is required.";
      valid = false;
    } else if (!emailRegex.test(userEmail)) {
      newErrors.userEmail = "Invalid email format.";
      valid = false;
    }

    const phoneRegex = /^(91)?\d{10}$/;
    if (!userPhone.trim()) {
      newErrors.userPhone = "Phone number is required.";
      valid = false;
    } else if (!phoneRegex.test(userPhone)) {
      newErrors.userPhone =
        "Phone number should be 10 digits (or 12 digits if starting with 91).";
      valid = false;
    }

    // if (!selectedState) {
    //   newErrors.selectedState = "State is required.";
    //   valid = false;
    // }

    // if (!selectedDistrict) {
    //   newErrors.selectedDistrict = "District is required.";
    //   valid = false;
    // }

    if (!message.trim()) {
      newErrors.message = "Message is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    emailjs.init("YOUR_PUBLIC_KEY"); // Use your actual public key here
  }, []);

  useEffect(() => {
    const loadedStates: State[] = (indianStates.states || []).map((state) => ({
      name: state.state,
      districts: state.districts || [],
    }));
    setStates(loadedStates);
  }, []);

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    setSelectedState(event.target.value);
    setSelectedDistrict("");
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateFields()) return;
    console.log("Form Values:", {
      user_name: userName,
      user_email: userEmail,
      user_phone: userPhone,
      state: selectedState,
      district: selectedDistrict,
      message: message,
    });

    // Check for required fields before sending
    if (
      !userName ||
      !userEmail ||
      !userPhone ||
      !selectedState ||
      !selectedDistrict ||
      !message
    ) {
      console.error("Missing required fields");
      return; // Prevent sending if any required fields are missing
    }

    // Prepare data to send to Google Apps Script
    const data = {
      name: userName,
      email: userEmail,
      phone: userPhone,
      state: selectedState,
      district: selectedDistrict,
      message: message,
    };

    // Send data to Google Apps Script
    fetch(
      // "https://script.google.com/macros/library/d/1WeyeL0mRyu1UvAIfE7MWNKCYUY5dIPTmjP93mXp5sxPwzVA1EHnUAxcN/1"
      "https://script.google.com/macros/s/AKfycbwh5wHnXeLbMf2LSgFxQTLacy3qlAA5BaQiup2fNMiBGUFOBb3ptc-fNLM-nCAttouABQ/exec",
      {
        // Replace with your actual web app URL
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "success") {
          console.log("Data added to sheet successfully");

          // Optionally send email using EmailJS as well
          if (form.current) {
            emailjs
              .sendForm(
                "service_voahtjf",
                "template_4cmsmjb",
                form.current,
                "lYakrV8ntyld3pBXN"
              )
              .then(
                () => {
                  console.log("Email sent successfully!");
                  // Clear the form
                  setUserName("");
                  setUserEmail("");
                  setUserPhone("");
                  setMessage("");
                  setSelectedState("");
                  setSelectedDistrict("");
                  // toast.success(
                  //   "Your mail has been sent successfully to OnestoLabs.",
                  //   {
                  //     position: "bottom-right",
                  //     autoClose: 5000,
                  //     hideProgressBar: false,
                  //     closeOnClick: true,
                  //     pauseOnHover: true,
                  //     draggable: true,
                  //   }
                  // );
                  toasterSuccess();
                },
                (error) => {
                  console.log("Email failed to send...", error.text);
                }
              );
          }
        } else {
          console.error("Error:", data.message);
          toast.error("Failed to send the message. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Request failed", error);
        toast.error("Failed to connect to the server. Please try again.");
      });
  };
  const cardsData = [
    {
      icon: RIbbonIcon,
      title: "High Value Ingredient Discovery & Creation",
    },
    {
      icon: WhiteHouse,
      title: "Formulation and Advanced Testing Platform",
    },
    {
      icon: HouseIcon,
      title: "Supply Chain Stability",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box>
        <Box className={Styles.ContactUs_form_container} ref={ref}>
          <Box className={Styles.ContactUs_form}>
            <Stack className={Styles.ContactUs_form_content}>
              <motion.div className={Styles.ContactUs_form_content_title}
               initial={{ opacity: 0, y: 10 }} 
               animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
               exit={{ opacity: 0 }} 
               transition={{
                 duration: 1, 
                 ease: "easeInOut", 
               }}
               >
                <span> Let’s talk </span>what works best for you
              </motion.div>
              <motion.div 
             className={Styles.ContactUs_form_content_subtitle}
             initial={{ opacity: 0, y: 10 }} 
             animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
             exit={{ opacity: 0 }} 
             transition={{
               duration: 1, 
               ease: "easeInOut", 
             }}>
                Please fill in your details and we will get
                <br /> back to you right away.
              </motion.div>
            </Stack>
            <motion.form
              ref={form}
              onSubmit={sendEmail}
              className={Styles.contact_us_section_form}
              initial={{ opacity: 0, y: 10 }} 
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
              exit={{ opacity: 0 }} 
              transition={{
                duration: 1, 
                ease: "easeInOut", 
              }}
            >
              <motion.div className={Styles.contact_us_section_form_field} >
                <Stack className={Styles.contactus_form_container_form}>
                  <Stack className={Styles.form_field_label}>
                    <Stack>
                      <input
                        type="text"
                        className={Styles.name_input}
                        placeholder="Name"
                        name="user_name" // Add name for EmailJS
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)} // Handle input change
                      />
                      {errors.userName && (
                        <p className={Styles.error}>{errors.userName}</p>
                      )}
                    </Stack>
                  </Stack>

                  <Stack
                    width={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    gap={"10px"}
                  >
                    <Stack className={Styles.form_field_sublabel}>
                      <Stack>
                        <input
                          type="email"
                          className={Styles.name_input}
                          placeholder="Email Address"
                          name="user_email" // Add name for EmailJS
                          value={userEmail}
                          onChange={(e) => setUserEmail(e.target.value)} // Handle input change
                        />
                        {errors.userEmail && (
                          <p className={Styles.error}>{errors.userEmail}</p>
                        )}
                      </Stack>
                    </Stack>
                    <Stack className={Styles.form_field_sublabel}>
                      <Stack>
                        <input
                          type="tel"
                          className={Styles.name_input}
                          placeholder="Phone Number"
                          name="user_phone" // Ensure this matches the expected variable
                          value={userPhone} // Make sure you're capturing this in state
                          onChange={(e) => setUserPhone(e.target.value)} // Handle input change
                        />{" "}
                        {errors.userPhone && (
                          <p className={Styles.error}>{errors.userPhone}</p>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>

                  <FormControl
                    fullWidth
                    className={Styles.form_field_label}
                    sx={{
                      borderRadius:"0",
                      height: { xs: "28px", md: "60px" },
                      "& .MuiOutlinedInput-root": {
      borderRadius: "0", // Applies to outlined inputs
    },
                      "& .MuiSelect-select": {
                        display: "flex",
                        alignItems: "left",
                        justifyContent: "left",

                      },
                    }}
                  >
                    <Select
                      displayEmpty
                      name="state" // Add name for EmailJS
                      value={selectedState}
                      onChange={handleStateChange}
                      className={Styles.select_input}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 30 * 10,
                            width: 250,
                          },
                        },
                      }}
                      renderValue={(selected) =>
                        selected ? (
                          selected
                        ) : (
                          <span style={{ color: "#878787" }}>Select State</span>
                        )
                      }
                      sx={{
                        fontSize: { xs: "10px", md: "18px" },
                        color: "#878787",
                        fontWeight: "600",
                        fontFamily: "Inter, sans-serif",
                        padding: { xs: "0 0px", md: "0 10px" },
                        
                      }}
                    >
                      <MenuItem disabled value="">
                        <span style={{ color: "#878787" }}>Select State</span>
                      </MenuItem>
                      {states.map((state, index) => (
                        <MenuItem key={index} value={state.name}>
                          {state.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {/* {errors.selectedState && (
                      <p className={Styles.error}>{errors.selectedState}</p>
                    )} */}
                  </FormControl>

                  <FormControl
                    fullWidth
                    className={Styles.form_field_label}
                    disabled={!selectedState}
                    sx={{ height: { xs: "28px", md: "60px" },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "0", // Applies to outlined inputs
                    }, }}
                  >
                    <Select
                      displayEmpty
                      name="district" // Add name for EmailJS
                      value={selectedDistrict}
                      onChange={(e) =>
                        setSelectedDistrict(e.target.value as string)
                      }
                      className={Styles.select_input}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 30 * 10,
                            width: 250,
                          },
                        },
                      }}
                      renderValue={(selected) =>
                        selected ? (
                          selected
                        ) : (
                          <span
                            style={{ color: "#878787", fontSize: "inherit",border:"0 !important", }}
                          >
                            Select District
                          </span>
                        )
                      }
                      sx={{
                        fontSize: { xs: "10px", md: "18px" },
                        color: "#878787",
                        fontWeight: "600",
                        fontFamily: "Inter, sans-serif",
                        padding: { xs: "0 0px", md: "0 10px" },
                        border:"0 !important",
                      }}
                    >
                      {selectedState &&
                        states
                          .find((state) => state.name === selectedState)
                          ?.districts.map((district, index) => (
                            <MenuItem key={index} value={district}>
                              {district}
                            </MenuItem>
                          ))}
                    </Select>
                    {/* {errors.selectedDistrict && (
                      <p className={Styles.error}>{errors.selectedDistrict}</p>
                    )} */}
                  </FormControl>

                  <Stack className={Styles.form_field_label}>
                    <Stack>
                      <TextareaAutosize
                        className={Styles.requiremnt}
                        id="outlined-required"
                        aria-label="minimum height"
                        placeholder="Type Your Message..."
                        minRows={3}
                        maxRows={3}
                        name="message" // Add name for EmailJS
                        value={message}
                        onChange={(e) => setMessage(e.target.value)} // Handle input change
                      />
                      {errors.message && (
                        <p className={Styles.error}>{errors.message}</p>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </motion.div>

              <Stack>
                <input
                  className={Styles.contact_us_section_form_btn}
                  type="submit"
                  value="Let's Connect"
                />
              </Stack>
            </motion.form>
          </Box>
        </Box>

        <Box className={Styles.ContactUs_socialMedia_container}>
          {/* <Stack className={Styles.ContactUs_values_card_layout}>
            {cardsData.map((option, index) => (
              <Stack key={index} className={Styles.ContactUs_values_card}>
                <Stack className={Styles.ContactUs_values_card_img}>
                  <img src={option.icon} />
                </Stack>
                <Stack className={Styles.ContactUs_values_card_title}>
                  {option.title}
                </Stack>
              </Stack>
            ))}
          </Stack> */}

          <Stack className={Styles.socialmedia_card_info_layout}>
            <Stack className={Styles.socialmedia_card}>
              <Stack className={Styles.socialmedia_card_icon}>
                <img src={MailIcon} alt="Mail Icon" />
              </Stack>
              <Stack className={Styles.socialmedia_card_title}>Email</Stack>
              <Stack className={Styles.socialmedia_card_text}>
              Get in touch with us:
              </Stack>
              <Stack
                className={Styles.socialmedia_card_email}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleMailtoClick("connect@onestolabs.com");
                }}
              >
                connect@onestolabs.com
              </Stack>
            </Stack>
            <Stack className={Styles.border} display={{ xs: "flex", md: "none" }}></Stack>
            <Stack className={Styles.socialmedia_card}>
              <Stack className={Styles.socialmedia_card_icon}>
                <img src={WhatsappIcon} alt="whatsapp Icon" />
              </Stack>
              <Stack className={Styles.socialmedia_card_title}>Chat</Stack>

              <Stack className={Styles.socialmedia_card_text}>
                Get in touch with us:
              </Stack>

              {/* this is for call */}

              {/* <Stack
                component="a"
                className={Styles.socialmedia_card_email}
                href="tel:+919654545148"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}
              > */}

              {/* this is for whatsapp */}
              <Stack
                className={Styles.socialmedia_card_whatsapp}
                component="a"
                href="https://api.whatsapp.com/send?phone=919654545148&amp;text=Hi" // 91 is the country code for India
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "underline",
                  color: "inherit",
                  cursor: "pointer",
                  fontWeight: "600", // Fixed the misplaced comma
                }}
              >
                +91 9654545148
              </Stack>
            </Stack>
            <Stack className={Styles.border} display={{ xs: "flex", md: "none" }}></Stack>
            <Stack className={Styles.socialmedia_card}>
              <Stack className={Styles.socialmedia_card_icon}>
                <img src={LocationIcon} alt="location Icon" />
              </Stack>
              <Stack className={Styles.socialmedia_card_title}>Office</Stack>
              <Stack className={Styles.socialmedia_card_text}>
              Plot 309 Sec 6, IMT Manesar ,
              Gurgaon, 122052
              </Stack>
              <Stack
                className={Styles.socialmedia_card_email}
                sx={{
                  textTransform: "capitalize !important",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleGetDirections(
                    "Plot 309 Sec 6, IMT Manesar, Gurgaon, 122052"
                 );
                }}
              >
                Get directions
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default Contact;
