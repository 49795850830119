import { Box, Stack } from "@mui/material";
import Styles from "./OurClient.module.css";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const OurClient = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, 
    threshold: 0.2,    
  });
  return (
    <>
      <Box className={Styles.summary_container}>
        <Stack className={Styles.summary_container_content} >
          <motion.div className={Styles.summary_container_title}
                initial={{ opacity: 0, y: 10 }} 
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
                exit={{ opacity: 0 }} 
                transition={{
                  duration: 1.5, 
                  ease: "easeInOut", 
                }}
           >
            Count on Us for Your&nbsp;
            <span className={Styles.summary_container_title_color}>
            Formulation Needs
            </span>
          </motion.div>
          <motion.div className={Styles.summary_container_info} ref={ref}
              initial={{ opacity: 0, y: 10 }} 
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
              exit={{ opacity: 0 }} 
              transition={{
                duration: 1.5, 
                ease: "easeInOut", 
              }}
          >
            At Onesto Labs, we go beyond manufacturing, we’re your partner in
            crafting a successful brand. With our commitment to honest
            formulations, we bring your product vision to life. Together, by
            combining your marketing know-how with our formulation and
            manufacturing expertise, we create products that resonate with
            consumers and provide lasting value.
          </motion.div>
        </Stack>

        <Stack className={Styles.our_client_container_image}>
          <motion.div className={Styles.our_client_personal_care} 
             initial={{ opacity: 0, y: 10 }} 
             animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
             exit={{ opacity: 0 }} 
             transition={{
               duration: 1.5, 
               ease: "easeInOut", 
             }}
           >
            <Stack className={Styles.our_client_container_personal_care_head}>
              Personal Care
            </Stack>
            <Stack className={Styles.our_client_container_personal_care}>
              <Stack className={Styles.box_img}>
              <img
                   src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-clients%2FArtboard%203.png?alt=media&token=eb2d6f6d-6c97-4b34-9498-4430bea6b2a9"} alt="Bare Anatomy" 
                  />
                {/* <img src={AnatonyIcon} alt="Bare Anatomy" /> */}
              </Stack>
              <Stack className={Styles.box_img}>
              <img
                  src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-clients%2FArtboard%201.png?alt=media&token=d35aeea0-3b2e-4a7c-959b-dc0d098a47a7"} alt="Chemist at Play"
                  />
                {/* <img src={ChemistAtPlayIcon} alt="Chemist at Play" /> */}
              </Stack>
              <Stack className={Styles.box_img}>
              <img
                src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-clients%2FArtboard%202.png?alt=media&token=322ba4db-7c8e-4fde-8332-7010e7881823"} alt="Sun Scoop"
                  />
                {/* <img src={SunScoopIcon} alt="Sun Scoop" /> */}
              </Stack>
            </Stack>
          </motion.div>

          <motion.div className={Styles.our_client_personal_care}
         initial={{ opacity: 0, y: 10 }} 
         animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
         exit={{ opacity: 0 }} 
         transition={{
           duration: 1.5, 
           ease: "easeInOut", 
         }}
         >
            <Stack className={Styles.our_client_container_personal_care_head}>
              Hygiene
            </Stack>
            <Stack className={Styles.our_client_container_personal_care}>
              <Stack className={Styles.box_img}>
              <img
               src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-clients%2FArtboard%206.png?alt=media&token=87e3ad7d-b66b-4b0d-9946-a229490bccb2"} alt="Ethik" 
                  />
                {/* <img src={EthikIcon} alt="Ethik" />{" "} */}
              </Stack>
              <Stack className={Styles.box_img}>
              <img
              src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-clients%2FArtboard%205.png?alt=media&token=37070c74-e2d3-4dd5-bfb4-816a9e5a3c5a"} alt="ugees"  
                  />
                {/* <img src={UgeesIcon} alt="ugees" /> */}
              </Stack>
              <Stack className={Styles.box_img}>
              <img
             src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-clients%2FArtboard%204.png?alt=media&token=34636502-24dd-4d51-8876-c80a2accfd5e"} alt="Koparo"  
                  />
                {/* <img src={KoparoIcon} alt="Koparo" />{" "} */}
              </Stack>
            </Stack>
          </motion.div>
        </Stack>

        {/* Moving Brand Images */}
        {/* <Stack className={Styles.summary_container_image}>
          <Stack className={Styles.image_container}>
            <img
              src={AnatonyIcon}
              alt="Bare Anatomy"
              className={
                Styles.moving_image
              } 
            />
            <img src={EthikIcon} alt="Ethik" className={Styles.moving_image} />{" "}
            <img
              src={ChemistAtPlayIcon}
              alt="Chemist at Play"
              className={Styles.moving_image}
            />
            <img
              src={KoparoIcon}
              alt="Koparo"
              className={Styles.moving_image}
            />{" "}
            <img
              src={SunScoopIcon}
              alt="Sun Scoop"
              className={Styles.moving_image}
            />
            <img src={UgeesIcon} alt="ugees" className={Styles.moving_image} />
          </Stack>
        </Stack> */}
      </Box>
    </>
  );
};

export default OurClient;
