import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Stack,
  TextareaAutosize,
  SelectChangeEvent,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Styles from "./ContactUsModal.module.css";
import CrossIcon from "./../../../assests/Cross.svg";
import emailjs from "@emailjs/browser";
import indianStates from "../../../Indian_states.json";
import { toast, ToastContainer } from "react-toastify";
import { SnackbarProvider, useSnackbar } from "notistack";
import MailIcon from "./../../../assests/MailBlue.svg";
import WhatsappIcon from "./../../../assests/WhatsappBlue.svg";
import LocationIcon from "./../../../assests/LocationBlue.svg";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

interface State {
  name: string;
  districts: string[];
}

const ContactUsModal: React.FC<ModalProps> = ({ open, handleClose }) => {
  const handleGetDirections = (address: any) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(googleMapsUrl, "_blank");
  };
  const handleMailtoClick = (mail: string) => {
    window.location.href = `mailto:${mail}`;
    console.log("Opening email client...");
  };
  const { enqueueSnackbar } = useSnackbar();
  const toasterSuccess = () => {
    enqueueSnackbar("Your mail has been sent successfully to OnestoLabs.", {
      variant: "success",
    });
  };
  const form = useRef<HTMLFormElement>(null); // Create a ref for the form
  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedDistrict, setSelectedDistrict] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [userEmail, setUserEmail] = useState<string>("");
  const [userPhone, setUserPhone] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState({
    userName: "",
    userEmail: "",
    userPhone: "",
    // selectedState: "",
    // selectedDistrict: "",
    message: "",
  });

  const validateFields = () => {
    let valid = true;
    const newErrors = {
      userName: "",
      userEmail: "",
      userPhone: "",
      // selectedState: "",
      // selectedDistrict: "",
      message: "",
    };

    if (!userName.trim()) {
      newErrors.userName = "Name is required.";
      valid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!userEmail.trim()) {
      newErrors.userEmail = "Email is required.";
      valid = false;
    } else if (!emailRegex.test(userEmail)) {
      newErrors.userEmail = "Invalid email format.";
      valid = false;
    }

    const phoneRegex = /^(91)?\d{10}$/;
    if (!userPhone.trim()) {
      newErrors.userPhone = "Phone number is required.";
      valid = false;
    } else if (!phoneRegex.test(userPhone)) {
      newErrors.userPhone =
        "Phone number should be 10 digits (or 12 digits if starting with 91).";
      valid = false;
    }

    // if (!selectedState) {
    //   newErrors.selectedState = "State is required.";
    //   valid = false;
    // }

    // if (!selectedDistrict) {
    //   newErrors.selectedDistrict = "District is required.";
    //   valid = false;
    // }

    if (!message.trim()) {
      newErrors.message = "Message is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  useEffect(() => {
    emailjs.init("YOUR_PUBLIC_KEY"); // Use your actual public key here
  }, []);

  useEffect(() => {
    const loadedStates: State[] = (indianStates.states || []).map((state) => ({
      name: state.state,
      districts: state.districts || [],
    }));
    setStates(loadedStates);
  }, []);

  const handleStateChange = (event: SelectChangeEvent<string>) => {
    setSelectedState(event.target.value);
    setSelectedDistrict("");
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateFields()) return;
    // console.log("Form Values:", {
    //   user_name: userName,
    //   user_email: userEmail,
    //   user_phone: userPhone,
    //   state: selectedState,
    //   district: selectedDistrict,
    //   message: message,
    // });

    // Check for required fields before sending
    if (
      !userName ||
      !userEmail ||
      !userPhone ||
      !selectedState ||
      !selectedDistrict ||
      !message
    ) {
      console.error("Missing required fields");
      return; // Prevent sending if any required fields are missing
    }

    // Prepare data to send to Google Apps Script
    const data = {
      name: userName,
      email: userEmail,
      phone: userPhone,
      state: selectedState,
      district: selectedDistrict,
      message: message,
    };

    // Send data to Google Apps Script
    fetch(
      // "https://script.google.com/macros/library/d/1WeyeL0mRyu1UvAIfE7MWNKCYUY5dIPTmjP93mXp5sxPwzVA1EHnUAxcN/1"
      "https://script.google.com/macros/s/AKfycbwh5wHnXeLbMf2LSgFxQTLacy3qlAA5BaQiup2fNMiBGUFOBb3ptc-fNLM-nCAttouABQ/exec",
      {
        // Replace with your actual web app URL
        method: "POST",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.result === "success") {
          console.log("Data added to sheet successfully");

          // Optionally send email using EmailJS as well
          if (form.current) {
            emailjs
              .sendForm(
                "service_voahtjf",
                "template_4cmsmjb",
                form.current,
                "lYakrV8ntyld3pBXN"
              )
              .then(
                () => {
                  console.log("Email sent successfully!");
                  // Clear the form
                  setUserName("");
                  setUserEmail("");
                  setUserPhone("");
                  setMessage("");
                  setSelectedState("");
                  setSelectedDistrict("");
                  // toast.success(
                  //   "Your mail has been sent successfully to OnestoLabs.",
                  //   {
                  //     position: "bottom-right",
                  //     autoClose: 5000,
                  //     hideProgressBar: false,
                  //     closeOnClick: true,
                  //     pauseOnHover: true,
                  //     draggable: true,
                  //   }
                  // );
                  toasterSuccess();
                  handleClose();
                },
                (error) => {
                  console.log("Email failed to send...", error.text);
                }
              );
          }
        } else {
          console.error("Error:", data.message);
          toast.error("Failed to send the message. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Request failed", error);
        toast.error("Failed to connect to the server. Please try again.");
      });
  };

  const ClearData = () => {
    setUserName("");
    setUserEmail("");
    setUserPhone("");
    setMessage("");
    setSelectedState("");
    setSelectedDistrict("");
    
  };

  return (
    <Modal
      open={open}
      onClose={()=>{handleClose();
        ClearData();
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        className={Styles.ContactUsModal_container}
        sx={{
          bgcolor: "background.paper",
        }}
      >
        <Stack
          className={Styles.modal_close}
          onClick={() => {
            handleClose();
            ClearData();
          }}
        >
          <img src={CrossIcon} />
        </Stack>
        <Stack className={Styles.contact_us_section_body}>
          <Stack className={Styles.contact_us_section_content}>
            <Stack className={Styles.contact_us_section_content_title}>
              <span> Let’s talk</span>
              what works best for you
            </Stack>

            <Stack className={Styles.contact_us_section_content_sub}>
              Please fill in your details and we will get back to you right
              away.
            </Stack>
            <Stack className={Styles.contact_us_icon} sx={{ display:{ xs: "none", md: "flex !important" } ,
          flexDirection:{xs: "none", md: "row"}}}>
             <Stack className={Styles.contact_us_icon_lay}    onClick={() => {
                  handleMailtoClick("connect@onestolabs.com");
                }}><img src={MailIcon}/></Stack>
             <Stack className={Styles.contact_us_icon_lay}   
              component="a"
                href="https://api.whatsapp.com/send?phone=919654545148&amp;text=Hi" // 91 is the country code for India
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }}><img src={WhatsappIcon}/></Stack>
             <Stack className={Styles.contact_us_icon_lay}  onClick={() => {
                  handleGetDirections(
                    "Plot 309 Sec 6, IMT Manesar, Gurgaon, 122052"
                  );
                }}><img src={LocationIcon}/></Stack>
            </Stack>
          </Stack>
          <form
            ref={form}
            onSubmit={sendEmail}
            className={Styles.contact_us_section_form}
          >
            <Stack className={Styles.contact_us_section_form_field}>
              <Stack className={Styles.contactus_form_container_form}>
                <Stack className={Styles.form_field_label}>
                  <Stack>
                    <input
                      type="text"
                      className={Styles.name_input}
                      placeholder="Name"
                      name="user_name" // Add name for EmailJS
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)} // Handle input change
                    />
                    {errors.userName && (
                      <p className={Styles.error}>{errors.userName}</p>
                    )}
                  </Stack>
                </Stack>

                <Stack
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"row"}
                  gap={"10px"}
                >
                  <Stack className={Styles.form_field_sublabel}>
                    <Stack>
                      <input
                        type="email"
                        className={Styles.name_input}
                        placeholder="Email Address"
                        name="user_email" // Add name for EmailJS
                        value={userEmail}
                        onChange={(e) => setUserEmail(e.target.value)} // Handle input change
                      />
                      {errors.userEmail && (
                        <p className={Styles.error}>{errors.userEmail}</p>
                      )}
                    </Stack>
                  </Stack>
                  <Stack className={Styles.form_field_sublabel}>
                    <Stack>
                      <input
                        type="tel"
                        className={Styles.name_input}
                        placeholder="Phone Number"
                        name="user_phone" // Ensure this matches the expected variable
                        value={userPhone} // Make sure you're capturing this in state
                        onChange={(e) => setUserPhone(e.target.value)} // Handle input change
                      />
                      {errors.userPhone && (
                        <p className={Styles.error}>{errors.userPhone}</p>
                      )}
                    </Stack>
                  </Stack>
                </Stack>

                <FormControl
                  fullWidth
                  className={Styles.form_field_label}
                  sx={{
                    height: { xs: "28px", md: "60px" },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "0", // Applies to outlined inputs
                    }, 
                    "& .MuiSelect-select": {
                      display: "flex",
                      alignItems: "left",
                      justifyContent: "left",
                    },
                  }}
                >
                  <Select
                    displayEmpty
                    name="state" // Add name for EmailJS
                    value={selectedState}
                    onChange={handleStateChange}
                    className={Styles.select_input}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 30 * 10,
                          width: 250,
                        },
                      },
                    }}
                    renderValue={(selected) =>
                      selected ? (
                        selected
                      ) : (
                        <span style={{ color: "#878787" }}>Select State</span>
                      )
                    }
                    sx={{
                      fontSize: { xs: "10px", md: "18px" },
                      color: "#878787",
                      fontWeight: "600",
                      fontFamily: "Inter, sans-serif",
                      padding: { xs: "0 0px", md: "0 10px" },
                    }}
                  >
                    <MenuItem disabled value="">
                      <span style={{ color: "#878787" }}>Select State</span>
                    </MenuItem>
                    {states.map((state, index) => (
                      <MenuItem key={index} value={state.name}>
                        {state.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* {errors.selectedState && (
                    <p className={Styles.error}>{errors.selectedState}</p>
                  )} */}
                </FormControl>

                <FormControl
                  fullWidth
                  className={Styles.form_field_label}
                  disabled={!selectedState}
                  sx={{ height: { xs: "28px", md: "60px" } ,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "0", // Applies to outlined inputs
                  }, }}
                >
                  {" "}
                  <Select
                    displayEmpty
                    name="district" // Add name for EmailJS
                    value={selectedDistrict}
                    onChange={(e) =>
                      setSelectedDistrict(e.target.value as string)
                    }
                    className={Styles.select_input}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 30 * 10,
                          width: 250,
                        },
                      },
                    }}
                    renderValue={(selected) =>
                      selected ? (
                        selected
                      ) : (
                        <span style={{ color: "#878787", fontSize: "inherit" }}>
                          Select District
                        </span>
                      )
                    }
                    sx={{
                      fontSize: { xs: "10px", md: "18px" },
                      color: "#878787",
                      fontWeight: "600",
                      fontFamily: "Inter, sans-serif",
                      padding: { xs: "0 0px", md: "0 10px" },
                    }}
                  >
                    {" "}
                    {selectedState &&
                      states
                        .find((state) => state.name === selectedState)
                        ?.districts.map((district, index) => (
                          <MenuItem key={index} value={district}>
                            {district}
                          </MenuItem>
                        ))}
                  </Select>
                  {/* {errors.selectedDistrict && (
                    <p className={Styles.error}>{errors.selectedDistrict}</p>
                  )} */}
                </FormControl>

                <Stack className={Styles.form_field_label}>
                  <Stack>
                    <TextareaAutosize
                      className={Styles.requiremnt}
                      id="outlined-required"
                      aria-label="minimum height"
                      placeholder="Type Your Message..."
                      minRows={3}
                      maxRows={3}
                      name="message" // Add name for EmailJS
                      value={message}
                      onChange={(e) => setMessage(e.target.value)} // Handle input change
                    />
                    {errors.message && (
                      <p className={Styles.error}>{errors.message}</p>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack>
              <input
                className={Styles.contact_us_section_form_btn}
                type="submit"
                value="Let's Connect"
              />
            </Stack>
          </form>
          <Stack className={Styles.contact_us_phone_icon} sx={{ display:{ xs: "flex", md: "none" },}}>
             <Stack className={Styles.contact_us_phone_lay}    onClick={() => {
                  handleMailtoClick("connect@onestolabs.com");
                }}><img src={MailIcon}/></Stack>
             <Stack className={Styles.contact_us_phone_lay}
                component="a"
                href="https://api.whatsapp.com/send?phone=919654545148&amp;text=Hi" // 91 is the country code for India
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                  cursor: "pointer",
                }} ><img src={WhatsappIcon}/></Stack>
             <Stack className={Styles.contact_us_phone_lay}  onClick={() => {
                  handleGetDirections(
                    "Plot 309 Sec 6, IMT Manesar, Gurgaon, 122052"
                  );
                }}><img src={LocationIcon}/></Stack>
            </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ContactUsModal;
