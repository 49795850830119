import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Styles from './PrivacyPolicy.module.css'

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <Box className={Styles.privacy_container}>
      <Stack className={Styles.privacy_container_head}>
      <span> Privacy Policy</span>
      </Stack>
      
      <Stack className={Styles.privacy_container_content}>
        <Typography variant="h5">Effective Date: <strong>20 November 2024</strong></Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          At Onesto Labs, we are dedicated to safeguarding your privacy and ensuring the security of your personal data. 
          This Privacy Policy explains how we collect, use, and protect the information you provide through our website and services. 
          By accessing or using the Onesto Labs website (https://onestolabs.com/), you agree to the practices outlined in this Privacy Policy.
        </Typography>

        <Typography variant="h5">1. Information Collected</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          We may collect the following categories of information when you interact with our Website:
        </Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          <strong>Personal Information:</strong> Includes your name, email address, phone number, and other contact details provided when completing forms or subscribing to newsletters.
        </Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          <strong>Usage Information:</strong> Encompasses details such as browsing activity, IP address, device information, and referring or exit pages.
        </Typography>

        <Typography variant="h5">2. Use of Collected Information</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          The information we gather is utilized for the following purposes:
        </Typography>
        <ul>
          <li><Typography variant="body1" className={Styles.body_content}>To deliver and enhance our products and services.</Typography></li>
          <li><Typography variant="body1" className={Styles.body_content}>To customize your experience and present content tailored to your preferences.</Typography></li>
          <li><Typography variant="body1" className={Styles.body_content}>To communicate with you, respond to inquiries, and provide support.</Typography></li>
          <li><Typography variant="body1" className={Styles.body_content}>To send promotional materials, newsletters, and marketing communications, subject to your consent.</Typography></li>
          <li><Typography variant="body1" className={Styles.body_content}>To analyze usage patterns and trends to improve Website functionality.</Typography></li>
        </ul>

        <Typography variant="h5">3. Information Sharing</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          We do not sell, trade, or lease your personal information to third parties. However, we may share your information with trusted service providers who assist in operating our Website and delivering our services. These providers are obligated to uphold the confidentiality and security of your data.
        </Typography>

        <Typography variant="h5">4. Cookies and Tracking Technologies</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          Cookies and similar technologies are used to gather information about your browsing behavior. Cookies are small data files stored on your device. You may modify your browser settings to manage or remove cookies, though doing so may impact the functionality of certain Website features.
        </Typography>

        <Typography variant="h5">5. Data Security</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          We employ industry-standard measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. While we strive to ensure robust security, no method of online transmission or electronic storage can guarantee absolute protection.
        </Typography>

        <Typography variant="h5">6. Your Rights and Choices</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          You have the option to withhold certain information or opt out of receiving promotional communications. Additionally, you can disable cookies via browser settings, though this may affect Website performance.
        </Typography>

        <Typography variant="h5">7. Children's Privacy</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          The Website is not intended for individuals under the age of 16. We do not knowingly collect personal data from children. If you believe we have inadvertently gathered information from a child under 16, please contact us for its removal.
        </Typography>

        <Typography variant="h5">8. Policy Updates</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          This Privacy Policy may be revised periodically. Updates will be reflected on this page, and the "Effective Date" will indicate the most recent changes.
        </Typography>

        <Typography variant="h5">9. Contact Information</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          For any questions, concerns, or requests related to this Privacy Policy or your personal information, please contact us at <a href="mailto:contact@onestolabs.com">contact@onestolabs.com</a>.<br/><br/>By accessing or using the Onesto Labs Website, you confirm that you have read, understood, and agreed to this Privacy Policy.
        </Typography>
      </Stack>
    </Box>
  );
}

export default PrivacyPolicy;
