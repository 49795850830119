import { Box, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Styles from "./Formulation.module.css";
import ContactUsModal from "../ContactModal/ContactUsModal";
import FormulationAccordion from "./FormulationAccordion";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import FormulationSummary from "./FormulationSummary";

const FormulationSection = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(1);

  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.4, // Adjust threshold for when the animation should trigger
  });

  const toggleAccordion = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (activeIndex === null) {
      setActiveIndex(1);
    }
  }, [activeIndex]);

  const formulationSteps = [
    {
      no: 1,
      icon: "link",
      head: "Pick your ",
      title: "formulation",
    },
    {
      no: 2,
      icon: "link",
      head: "select your ",
      title: "packaging",
    },
    {
      no: 3,
      icon: "link",
      head: "Product delivery ",
      title: "in 60 days",
    },
  ];

  return (
    <>
      <Box className={Styles.formulation_section_container}>
        {/* Accordion Section */}
        <Stack className={Styles.formulation_section_part1}>
          <Stack className={Styles.left}>
            <Stack
            ref={ref}
              className={Styles.left_title}
              sx={{ display: { xs: "none", md: "flex" } }}
              marginTop={"5px"}
            >
              Here's Your Guide to{" "}
              <span style={{ display: "contents" }}>
                Crafting Effective Product Innovations{" "}
              </span>
              with Us
            </Stack>
            <motion.div className={Styles.left_accordion} 
            
              initial={{ opacity: 0, y: 10 }}
              animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 1.5,
                ease: "easeInOut",
              }}
            >
              <FormulationAccordion
                serialNo="1"
                title="Pick your "
                subtitle="formulation"
                content="Use our extensive formulation repository to customize or create new formulations for your brand."
                isActive={activeIndex === 1}
                onToggle={() => toggleAccordion(1)}
              />
              <FormulationAccordion
                serialNo="2"
                title="Select your "
                subtitle="packaging"
                content="Choose the right packaging for your brand needs with a wide variety of options like sustainable bottles, airtight containers, eco-friendly plastics, and more."
                isActive={activeIndex === 2}
                onToggle={() => toggleAccordion(2)}
              />
              <FormulationAccordion
                serialNo="3"
                title="Product delivery in "
                subtitle="60 days"
                content="Once approved, our team manages everything, so you can prioritize marketing and launch."
                isActive={activeIndex === 3}
                onToggle={() => toggleAccordion(3)}
              />
            </motion.div>
          </Stack>

          <Stack className={Styles.right}  ref={ref}>
            {activeIndex === 1 ? (
              <Stack className={Styles.right_video} >
                <video
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/formulation%2FTexture.mp4?alt=media&token=39b070d7-da60-46ab-9388-4e0b67c9db09"
                  }
                  muted
                  loop
                  autoPlay
                  playsInline
                  onCanPlay={() => console.log("Video loaded successfully")}
                />
              </Stack>
            ) : activeIndex === 2 ? (
              <Stack className={Styles.right_video}>
                <video
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/formulation%2FPackaging.mp4?alt=media&token=a2ae9548-5015-4023-90ff-b5c8d42f58cb"
                  }
                  muted
                  loop
                  autoPlay
                  playsInline
                />
              </Stack>
            ) : (
              <Stack className={Styles.right_video}>
                <video
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/formulation%2Fhands-delivery-and-box-with-green-screen-for-ship-2024-10-03-19-30-20-utc_1.mp4?alt=media&token=782d9631-c2aa-445f-acca-f130bbb59bb1"
                  }
                  muted
                  loop
                  autoPlay
                  playsInline
                />
              </Stack>
            )}
          </Stack>
          <Box    className={Styles.left_title} sx={{ display: { xs: "flex", md: "none" } }}>
          <motion.div
          
           
            initial={{ opacity: 0, y: 10 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }}
            exit={{ opacity: 0 }}
            transition={{
              duration: 1.5,
              ease: "easeInOut",
            }}
          >
            Here's Your Guide to{" "}
            <span>Crafting Effective Product Innovations </span>with Us
          </motion.div>
          </Box>
        
        </Stack>

     <FormulationSummary />
      </Box>
      <ContactUsModal open={open} handleClose={handleClose} />
    </>
  );
};

export default FormulationSection;
