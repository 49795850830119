import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import Styles from "./OurStorySection.module.css";
import ContactUsModal from "../ContactModal/ContactUsModal";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

const OurStorybottom = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { ref, inView } = useInView({
      triggerOnce: true, // Animation triggers only once
      threshold: 0.2, // Adjust threshold for when the animation should trigger
    });

    return(<>
    
    <Stack className={Styles.our_story_container_bot} ref={ref}>
          <motion.div
            className={Styles.bot_content}
            initial={{ opacity: 0, y: 10 }} // Start hidden and slightly below
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} // Fade in and move up when in view
            exit={{ opacity: 0 }} // Fade out when exiting
            transition={{
              duration: 1.5, // Duration of the animation
              ease: "easeInOut", // Smooth fade-in and fade-out
            }}
          >
            <Stack className={Styles.bot_content_title}>
              Consider Us as <span>Your Trusted Team </span>for
              <span> Honest Formulations</span>
            </Stack>
            <Stack className={Styles.bot_content_subtitle}>
              At Onesto Labs, honesty is at the heart of everything we do. We
              create formulations that are reliable, effective, and
              results-oriented, ensuring our partners receive premium-quality
              products. Our expert team supports you from start to finish,
              helping you achieve your product goals every step of the way.
            </Stack>
            <Stack
              className={Styles.bot_content_btn}
              onClick={() => {
                handleOpen();
              }}
            >
              Get in Touch
            </Stack>
          </motion.div>
          <Stack className={Styles.bot_card}>
            <Stack className={Styles.bot_card_layout}>
              {" "}
              <Stack className={Styles.bot_card_content1}>
                <Stack className={Styles.bot_card_value}>
                  150<span>+</span>
                </Stack>
                <Stack className={Styles.bot_card_title}>
                  Products <br />
                  Manufactured
                </Stack>
              </Stack>
              <Stack className={Styles.bot_card_content2}>
                <Stack className={Styles.bot_card_value}>
                  20<span>+</span>
                </Stack>
                <Stack className={Styles.bot_card_title}>
                  Countries, Ingredients Sourced From
                </Stack>
              </Stack>
            </Stack>
            <Stack className={Styles.bot_card_layout2}>
              {" "}
              <Stack className={Styles.part3_img}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our%20story%20section%2Fonestolabs_cosmetics.png?alt=media&token=a97172ac-87ff-41ee-b3e3-3325fb3e954b"} />
              </Stack>
              <Stack className={Styles.part3_img}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our%20story%20section%2Fonestolabs_cruelty.png?alt=media&token=04d887ba-f6d6-4345-9584-bae3a1d3cf95"} />
              </Stack>
              <Stack className={Styles.part3_img}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our%20story%20section%2Fonestolabs_gmp.png?alt=media&token=f273ffe4-c30e-49c9-807b-274772723772"} />
              </Stack>
              <Stack className={Styles.part3_img}>
                <img src={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our%20story%20section%2Fonestolabs_ifra.png?alt=media&token=adcbdd10-2f2b-45bb-a49c-e9390aac3d8b"} />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <ContactUsModal open={open} handleClose={handleClose} />
    </>)
}

export default OurStorybottom;