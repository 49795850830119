import React, { MutableRefObject, useEffect, useState } from "react";
import Styles from "./Home.module.css";
import { Box, Stack } from "@mui/material";
import FaqAccordion from "./FAQSection/FaqAccordion";
import OurClient from "./OurClientSection/OurClient";
import FormulationSection from "./FormulationSection/FormulationSection";
import ContactUsSection from "./ContactUsSection/ContactUsSection";
import ContactUsModal from "./ContactModal/ContactUsModal";
import OurStorySection from "./OurStory/OurStorySection";
import ServiceSection from "./Service/ServiceSection";
import ProductSection from "./ProductSection/ProductSection";
import VisionSection from "./VisionSection/VisionSection";

declare module "react" {
  interface VideoHTMLAttributes<T> extends React.HTMLAttributes<T> {
    fetchpriority?: string;
  }
}

type HomeProps = {
  faqRef: MutableRefObject<HTMLDivElement | null>;
  clientRef: MutableRefObject<HTMLDivElement | null>;
  storyRef: MutableRefObject<HTMLDivElement | null>;
  serviceRef: MutableRefObject<HTMLDivElement | null>;
  productRef: MutableRefObject<HTMLDivElement | null>;
};

const Home: React.FC<HomeProps> = ({
  faqRef,
  clientRef,
  storyRef,
  serviceRef,
  productRef,
}) => {
  const [showAll, setShowAll] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Array of FAQ data
  const faqItems = [
    {
      title: "How do I get started with a new product?",
      content:
       "To get started with a new product, please share your requirements through the “Contact Us” form and fill in the product brief and the other information. " },
    {
      title: "Which regulatory certifications does your facility hold?",
      content:"Our manufacturing facility holds all the necessary certifications including the State FDA, Ayush FDA, GMP, Fire NOC, and Pollution NOC. To know more about our certifications, please reach out to us."
          },
    {
      title: "What is the minimum order quantity (MOQ) for each product?",
      content:"The Minimum Order Quantity (MOQ) depends upon the type of product you want to manufacture."
         },
    {
      title: "What are the costs involved in product development?",
      content:
        "The cost involved in product development varies depending on the product type. Thus, please connect with us to know the actual costs for the product you want to formulate/ manufacture. ",
    },
    {
      title: "What information do you need to design a formulation tailored to my brand?",
      content:"To design a formulation tailored to your brand, we would be needing a brief regarding that product development. It would help us to deliver the ideal product as per your requirements. "
         },
         {
          title: "How long does delivery take?",
          content:"The average time that we take to deliver the products is 45 to 60 days. However, depending on the product type and order quantity, the delivery time can vary. "
        },
        {
          title: "Do I need to provide my own formulation?",
          content:"It is not necessary to provide your formulation. Our team of experts will help you formulate the best one. However, if you already have a formulation ready with you, we can simply help you manufacture the products in our State-of-the-art facility. " 
         },
         {title:"Can you assist with packaging selection? ",
          content:"Definitely! Our team can help you assist with your packaging selection.  "
         },
         {title:"Can I choose a formulation that Onesto Labs already produces for another brand?",
          content:"No, we do not encourage opting for the same formulation that Onesto Labs has already produced for another brand as it is against our practice. However, our experts can help fulfil your requirements and craft products as per your brand’s vision. "
         },
       

  ];

  // Function to show more FAQs
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box className={Styles.main_container}>
        {/* Home Banner */}
        <Box className={Styles.home_container}>
          <Stack className={Styles.homepage_banner}>
            <video
              src="https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/main-video%2FBA_R%26D_For%20Banner%201280x720_without%20audio_v1.3.mp4?alt=media&token=9cf9e08f-377c-45c6-9325-0c70eadf5d79"
              poster={"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/main-video%2FPoster_MainVideo_Banner.jpg?alt=media&token=b1bf10ab-4abb-4ca6-b945-bdac5fe9ce2d"}
              muted
              loop
              autoPlay
              playsInline
               preload="metadata"
              fetchpriority="high"
              style={{ width: "100%", height: "100vh" }}
              onCanPlay={() => console.log("Video loaded successfully")}
            />
          </Stack>
        </Box>

        <Stack className={Styles.homepage_banner_content}>
          <Stack className={Styles.homepage_banner_title}>
            Dedicated{" "}
            <span>
              R&D Expert and <br />
              Manufacturing Partner
            </span>{" "}
            <br />
            for your brand
          </Stack>
          <Stack className={Styles.homepage_banner_subtitle}>
            We design and manufacture high-performance formulations, tailored to create successful products for our partner
            brands. We own the entire process from ideation to execution.
          </Stack>
          <Stack className={Styles.homepage_banner_layout_btn}>
          <Stack className={Styles.homepage_banner_btn}>
            <Stack
              className={Styles.req_btn}
              onClick={() => {
                handleOpen();
              }}
            >
              Let's Connect
            </Stack>
          </Stack>
          </Stack>
         

        </Stack>
        {/* ------------End----- */}

        {/* Our Product */}
        <Box ref={productRef} id={"ourProduct"}>
          {" "}
          <ProductSection />
        </Box>

        {/* Our Story */}
        <Box ref={storyRef} className={Styles.story_section_container}>
          <OurStorySection />
        </Box>

        {/* Our Client Section */}
        <Box ref={clientRef} className={Styles.summary_section_container}>
          <OurClient />
        </Box>

        {/* Our Vision */}
        <VisionSection />

        {/* Our Service */}

        <Box ref={serviceRef}>
          {" "}
          <ServiceSection />
        </Box>

        {/* Formulation Section */}
        <FormulationSection />

        {/* FAQs Section */}
        <Box ref={faqRef} className={Styles.faq_section_container}>
          <Stack className={Styles.faq_section_container_title}>
            Frequently asked{" "}
            <span className={Styles.faq_section_container_title_part}>
              questions
            </span>
          </Stack>{" "}
          <Stack className={Styles.faq_section_accordion}>
            {/* Initially show only 2 FAQ items */}
            {faqItems
              .slice(0, showAll ? faqItems.length : 2)
              .map((faq, index) => (
                <FaqAccordion
                  key={index}
                  title={faq.title}
                  content={faq.content}
                />
              ))}

            {/* Toggle button to show more/less */}
            {showAll && 
           <Stack className={Styles.notlisted_faq}>
              <Stack className={Styles.notlisted_faq_head}>Is your question not listed here? </Stack>
              <Stack className={Styles.notlisted_faq_content}>Don’t worry, fill out the form below and our team will get back to you at the earliest.</Stack>
            </Stack>
            }
            <Stack
              onClick={toggleShowAll}
              className={Styles.view_more}
              style={{ cursor: "pointer", color: "blue", marginTop: "2%" }}
            >
              {showAll ? "View Less" : "View More"}
            </Stack>
         
          </Stack>

        </Box>

        {/* ContactUs Section */}
        <ContactUsSection />
        {/* --------------------End FAQs------------ */}
      </Box>
      <ContactUsModal open={open} handleClose={handleClose} />
    </>
  );
};

export default Home;
