import React, { useState } from "react";
import Styles from "./ProductSection.module.css";
import { Box, Modal, Stack } from "@mui/material";
import BodycareIcon from "./../../../assests/BodyCareIcon.svg";
import FacecareIcon from "./../../../assests/FacecareIcon.svg";
import SuncareIcon from "./../../../assests/SuncareIcon.svg";
import HaircareIcon from "./../../../assests/HaircareIcon.svg";
import BabycareIcon from "./../../../assests/BabycareIcon.svg";
import FragranceIcon from "./../../../assests/FragranceIcon.svg";
import HygieneIcon from "./../../../assests/HygieneIcon.svg";
import PetcareIcon from "./../../../assests/PetcareIcon.svg";
import { useNavigate } from "react-router-dom";
import CrossIcon from "./../../../assests/Cross.svg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

interface product {
  title: string;
  icon: string;
  gif: string;
  modalData: {
    content: string;
    points: string[];
    content2: string;
  };
}
const ProductSection = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = useState<product>({
    title: "",
    icon: "",
    gif:"",
    modalData: {
      content: "",
      points: [],
      content2: "",
    },
  });

  const navigate = useNavigate();

  const handleOpenModal = (product: product) => {
    setModalData(product);
    handleOpen();
  };

  const productData = [
    {
      icon: BodycareIcon,
      title: "<b>Body Care</b>",
      gif:"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-products%2Flegs.gif?alt=media&token=2a57d72e-8ed9-4770-9252-f88770112970",

      modalData: {
        content:
          "We specialize in manufacturing a broad range of body care products designed to nourish, cleanse, and refresh the skin.<br/>Every formulation is crafted to meet the highest standards, being free from harmful chemicals, clinically tested, and made with premium quality ingredients. Our offerings go beyond these features, as we remain dedicated to delivering the best in body care.<br/><b>Our Body Care Range Includes:<b><br/> ",
        points: [
          " Body Lotions",
          " Body Washes",
          " Body Oils",
          " Body Scrubs",
          " Body Butters",
          " Skin Roll-Ons",
        ],
        content2:
          "Whether you’re looking for the products listed here or a custom solution, we’re here to create the ideal body care solution for you.",
      },
    },
    {
      icon: FacecareIcon,
      gif:"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-products%2Fface%20care.gif?alt=media&token=0f84ac45-f587-4b83-8165-aeb6f72bff41",
      title: "<b>Face Care</b>",
      modalData: {
        content:
          "We specialize in manufacturing face care products designed to enhance skincare routines with scientifically backed formulations. Our focus is on creating products that deliver effective, long-lasting results, while ensuring safety and efficacy by avoiding harmful chemicals.<br/><b>Our Face Care Range Includes:<b/>",
        points: [
          "Face Cream",
          "Face Serum",
          "Face Wash (Foam, Cream, Gel)",
          "Toner",
          "Lip Scrub",
          "Lip Mask",
        ],
        content2:
          "<b>Together, we can create your ideal product</b><br/>Whether you’re looking for our existing offerings here or need a custom solution, we’re here to create the perfect face care product for your brand.",
      },
    },

    {
      icon: SuncareIcon,
      gif:"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-products%2Fuv-protection.gif?alt=media&token=ca6fd409-7a54-4fd4-bfbd-94e60eea2466",
      title: "<b>Sun Care</b>",
      modalData: {
        content:
          "Our expert formulations combine essential ingredients with high-performance SPF to protect the skin from harmful UV rays, while also nourishing and caring for it.<br/>We specialize in manufacturing a range of sun care solutions, including Face Sunscreens, Body Sunscreens, and Sunscreen Sticks, tailored to meet diverse client needs.<br/><b>Our sunscreens excel at:</b>",
        points: [
          "High SPF Protection",
          "Fast Absorption / No White Cast ",
          "Prevention of Photoaging & Sun Damage",
          "Skin-Friendly & Irritation-Free",
        ],
        content2:
          "<b>Partner with us to create safe, effective, and high-quality sun care solutions.</b><br/>We ensure our products are research-driven, made with premium ingredients sourced globally, and clinically proven to protect without harming the skin.",
      },
    },
    {
      icon: HaircareIcon,
      gif:"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-products%2FHair.gif?alt=media&token=564c0529-34b3-4351-88c8-b5cee6efe741",
      title: "<b>Hair Care</b>",
      modalData: {
        content:
          "We manufacture a wide range of hair care products, crafted with science-backed ingredients and free from harmful chemicals, sulphates, and parabens.<br/>Our formulations are research-driven and crafted with premium ingredients sourced globally. The products are clinically tested for safety and efficacy, free from harmful chemicals, and deliver effective results as expected.<br/><b>Our Hair Care Range Includes:</b>",
        points: [
          "Shampoo",
          "Conditioner",
          "Serum",
          "Mask",
          "Spray",
          "Oil",
          "Wax",
        ],
        content2:
          "The list doesn’t end here—if you’re looking for any of the products mentioned above or a custom solution, let’s talk and create the perfect hair care line for you.",
      },
    },
    {
      icon: BabycareIcon,
      gif:"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-products%2Fpediatrics.gif?alt=media&token=c2dbfde5-8374-4d52-a227-8fba1ca56136",
      title: "<b>Baby Care</b>",
      modalData: {
        content:
          "We take extra care in crafting baby care products that meet the highest safety standards, offering gentle and nourishing formulations for delicate skin. <br/>Our products are thoughtfully developed with safe, hypoallergenic ingredients, ensuring they are free from harsh chemicals like sulfates, parabens, and fragrances. Designed for daily use, they provide the gentle care babies deserve, without compromising on quality or safety.<br/><b>Our Baby Care Range Includes:</b>",
        points: ["Shampoo", "Hair Oil", "Massage Oil"],
        content2:
          "Whether you're looking for the products listed here or a custom solution, let's work together to create the perfect baby care line for you.",
      },
    },
    {
      icon: FragranceIcon,
      gif:"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-products%2Fperfume.gif?alt=media&token=7e90cac9-3d15-4257-b823-bd0ce7624f5c",
      title: "<b>Aromatherapy</b>",
      modalData: {
        content:
          "Our products are carefully crafted with premium, natural essential oils, focusing on the therapeutic properties that elevate the sensory experience and enhance well-being.<br/>Free from harsh chemicals, our formulations are designed to create calming atmospheres and uplift mood, ensuring each use provides a soothing, aromatic experience.<br/><b>Our Aromatherapy Range Includes: </b> ",
        points: ["Aromatherapy Mists", "Bath Oils", "Hand and Body Washes"],
        content2:
          "If you're interested in these offerings or a custom solution, we’re ready to help craft the perfect aromatherapy collection for your brand.",
      },
    },
    {
      icon: HygieneIcon,
      gif:"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-products%2Fhygiene.gif?alt=media&token=37d49b84-a0c9-4bae-89a4-562f80cb802d",
      title: "<b>hygiene<b/>",
      modalData: {
        content:
          "We manufacture high-quality hygiene products that prioritize cleanliness and safety in everyday life. Our formulations are crafted to effectively cleanse, ensuring a gentle approach to both skin care and home hygiene. With our focus on quality, we empower your brand to provide trustworthy hygiene solutions.<br/><b>Our Hygiene Range Includes:</b>",
        points: [
          "Hand Wash",
          "Home Care Products",
          "Intimate Care Products",
          "Antibacterial Gel",
        ],
        content2:
          "There’s more we can do—whether it’s these products or a customized solution, let’s discuss how we can create the best hygiene products that suits your brand perfectly.",
      },
    },
    {
      icon: PetcareIcon,
      gif:"https://firebasestorage.googleapis.com/v0/b/onestolabs-09.firebasestorage.app/o/our-products%2Fpet-care.gif?alt=media&token=ec50898a-4cfc-4b6f-bb21-407e3318b7f1",
      title: "<b>Pet Care</b>",
      modalData: {
        content:
          "Our pet care products are thoughtfully developed to provide safe, high-quality solutions for grooming and hygiene, crafted with natural ingredients that are gentle yet effective.<br/>Each formulation is designed to keep pets healthy and comfortable, free from harsh chemicals, and backed by veterinarian approval to ensure the best care.<br/><b>Our Pet Care Range Includes:</b> ",
        points: [
          "Anti-Flea Solutions",
          "Anti-Tick Solutions",
          "Anti-Irritant Products",
        ],
        content2:
          "Whether you’re looking for these products or a custom formulation tailored to your brand, we’re here to create a pet care line that prioritizes quality, safety, and the well-being of pets.",
      },
    },
  ];

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation triggers only once
    threshold: 0.2,    // Adjust threshold for when the animation should trigger
  });

  return (
    <>
      <Box className={Styles.product_section_container} ref={ref}>
        <Stack className={Styles.section_head}>
        <motion.div
        initial={{ opacity: 0, y: 10 }} // Start hidden and slightly below
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} // Fade in and move up when in view
        exit={{ opacity: 0 }} // Fade out when exiting
        transition={{
          duration: 0.8, // Duration of the animation
          ease: "easeInOut", // Smooth fade-in and fade-out
        }}
      >
          <Stack className={Styles.product_section_head}>
            What We Can <span>Create Together</span>
          </Stack>
          {/* <Stack className={Styles.product_section_subhead}>
            Let’s Bring Your Ideas to Life
          </Stack> */}
          </motion.div>
        </Stack>
      
        <motion.div className={Styles.product_section_content}
     initial={{ opacity: 0, y: 10 }} // Start hidden and slightly below
     animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} // Fade in and move up when in view
     exit={{ opacity: 0 }} // Fade out when exiting
     transition={{
       duration: 2, // Duration of the animation
       ease: "easeInOut", // Smooth fade-in and fade-out
     }}
        >
          {productData.map((product, index) => (
            <Stack
              key={index}
              className={Styles.card_layout}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Stack 
               className={` ${
                hoveredIndex === index ? Styles.card_layout_icon_hovered : Styles.card_layout_icon
              }`}>
                <Stack
                  className={` ${
                    hoveredIndex === index ? Styles.card_icon_hovered : Styles.card_icon
                  }`}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                {hoveredIndex === index ?  
               
              //   <img
              //   src={product.gif}
              //   alt={`${product.title} gif`}
              //   onLoad={() => console.log("GIF loaded successfully")}
              // />
              <img
              src={product.gif}
              alt={`${product.title} gif`}
                  />

                :  
                // <img src={product.icon} alt={`${product.title} Icon`} />
                <img
                    src={product.icon}
                    alt={`${product.title} Icon`}
                  />
                }
                </Stack>
                <Stack
                  className={` ${
                    hoveredIndex === index ? Styles.card_title_hovered : Styles.card_title
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: product?.title,
                  }}
                />
              </Stack>
              <Stack
                
                className={` ${
                  hoveredIndex === index ? Styles.card_layout_btn_hovered : Styles.card_layout_btn
                }`}
                onClick={() => {
                  handleOpenModal(product);
                }}
              >
                {"->"}
              </Stack>
            </Stack>
          ))}
        </motion.div >
        
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        {modalData && (
          <Box
            className={Styles.Modal_container}
            sx={{
              bgcolor: "background.paper",
            }}
          >
            <Stack className={Styles.Modal_head}>
              <Stack
                className={Styles.Modal_title}
                dangerouslySetInnerHTML={{
                  __html: modalData?.title,
                }}
              />

              <Stack
                className={Styles.modal_close}
                onClick={() => {
                  handleClose();
                }}
              >
                <img src={CrossIcon} />
              </Stack>
            </Stack>

            <Stack className={Styles.Modal_content}>
              {" "}
              <Stack className={Styles.Modal_content_layout}>
                <Stack
                  className={Styles.Modal_content_info}
                  dangerouslySetInnerHTML={{
                    __html: modalData.modalData.content,
                  }}
                />
                <Stack className={Styles.Modal_content_points}>
                  {modalData.modalData.points.map((point, index) => (
                    <Stack key={index} className={Styles.point_item}>
                      {"• "}
                      {point}
                    </Stack>
                  ))}
                </Stack>
                <Stack
                  className={Styles.Modal_content_info}
                  dangerouslySetInnerHTML={{
                    __html: modalData.modalData?.content2,
                  }}
                />
              </Stack>
            </Stack>
            <Stack
              className={Styles.bot_content_btn}
              onClick={() => {
                navigate("/contactus");
              }}
            >
              Let's Talk
            </Stack>
          </Box>
        )}
      </Modal>
    </>
  );
};

export default ProductSection;
