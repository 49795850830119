
import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Styles from './Terms.module.css';

const TermsofService = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <Box className={Styles.terms_container}>
      <Stack className={Styles.terms_container_head}>

        <span>Terms of Service</span>
      </Stack>

      <Stack className={Styles.terms_container_content}>
        <Typography variant="h5">Effective Date: <strong>20 November 2024</strong></Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          These Terms of Use ("Terms") set forth the conditions for accessing and utilizing the Onesto Labs website, located at 31, Ground Floor Panchkuian Road, Central Delhi, New Delhi, Delhi, India, 110001, accessible via https://onestolabs.com/. Kindly review these Terms thoroughly before engaging with the Website.
        </Typography>

        <Typography variant="h5">1. Agreement to Terms</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          By accessing or using the Website, you acknowledge and agree to comply with these Terms. If you do not agree to any provision of these Terms, you are prohibited from using the Website.
        </Typography>

        <Typography variant="h5">2. Amendments to Terms</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          We retain the right to amend or update these Terms at any time. Such changes will take effect upon their publication on the Website. Your continued use of the Website following the posting of changes signifies your acceptance of the revised Terms.
        </Typography>

        <Typography variant="h5">3. Submission of Content</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          You are solely responsible for any content you submit, post, or transmit via the Website, including but not limited to text, images, and other media ("User Content"). By submitting User Content, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, distribute, and display the User Content on the Website.
        </Typography>

        <Typography variant="h5">4. Ownership of Intellectual Property</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          All materials on the Website, including but not limited to text, graphics, logos, icons, images, audio clips, and software, are owned by Onesto Labs or its licensors and are protected by copyright and other intellectual property laws.
        </Typography>

        <Typography variant="h5">5. Prohibited Uses</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          You agree to refrain from using the Website for any unlawful or unauthorized purpose. You may not alter, reproduce, distribute, display, create derivative works of, or otherwise exploit any content from the Website without prior written consent from Onesto Labs.
        </Typography>

        <Typography variant="h5">6. Privacy Practices</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          Our Privacy Policy details the methods by which we collect, utilize, and safeguard your personal information. By accessing or using the Website, you consent to the practices outlined in the Privacy Policy.
        </Typography>

        <Typography variant="h5">7. Disclaimer of Warranties</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          The Website is provided on an "as is" and "as available" basis. We make no representations or warranties, express or implied, regarding the accuracy, completeness, or reliability of the content on the Website.
        </Typography>

        <Typography variant="h5">8. Limitation of Responsibility</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          To the maximum extent permitted by law, Onesto Labs and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of the Website.
        </Typography>

        <Typography variant="h5">9. Indemnification</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          You agree to indemnify and hold harmless Onesto Labs, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorney's fees, arising from your use of the Website.
        </Typography>

        <Typography variant="h5">10. Governing Laws and Jurisdiction</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          These Terms are governed by and shall be construed in accordance with the laws of the State of Texas, excluding its conflict of law provisions.
        </Typography>

        <Typography variant="h5">11. Contact Details</Typography>
        <Typography variant="body1" className={Styles.body_content} paragraph>
          For any questions or concerns regarding these Terms, please contact us at <a href="mailto:contact@onestolabs.com">contact@onestolabs.com</a>.
        </Typography>

        <Typography variant="body1" className={Styles.body_content} paragraph>
          By accessing or using the Onesto Labs Website, you confirm that you have read, understood, and agreed to these Terms of Use.
        </Typography>
      </Stack>
    </Box>
  );
}

export default TermsofService;
