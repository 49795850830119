import React, { useRef, useState } from "react";
import Styles from "./Formulation.module.css";
import { Box, Stack } from "@mui/material";
import DownArrow from "./../../../assests/DownArrow.svg";
import ContactUsModal from "../ContactModal/ContactUsModal";

interface AccordionProps {
  serialNo: string;
  title: string;
  content: string;
  subtitle: string;
  isActive: boolean;
  onToggle: () => void;
}

const FormulationAccordion: React.FC<AccordionProps> = ({
  serialNo,
  title,
  content,
  subtitle,
  isActive,
  onToggle,
}) => {
  const contents = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Box
        sx={{ display: "flex", flexDirection: "column" }}
        className={Styles.accordion_layout}
      >
        <div className={Styles.accordion__section}>
          <div className={`${Styles.accordion} ${isActive ? "active" : ""}`}>
            <Stack onClick={onToggle} className={Styles.accordion__title}>
              <Stack className={Styles.accordion__title_step}>
                <span
                  className={Styles.accordion__title_step_text}
                  style={{
                    color: isActive ? " #3575C1" : "",
                  }}
                >
                  Step
                </span>{" "}
                <span
                  className={Styles.accordion__title_step_no}
                  style={{
                    color: isActive ? " #3575C1" : "",
                  }}
                >
                  {serialNo}
                </span>
              </Stack>
              <Stack className={Styles.accordion__title_tt}>
                {title}
                <span>{subtitle}</span>
              </Stack>
            </Stack>
            <Stack
              className={Styles.accordion__section_down}
              onClick={onToggle}
              style={{ padding: "0 6px 4px 0" }}
            >
              <img
                src={DownArrow}
                alt="downArrow"
                style={{
                  transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            </Stack>
          </div>
          <div
            ref={contents}
            style={{
              maxHeight: isActive ? "150px" : "0px",
              overflow: "hidden",
              transition: "max-height 0.3s ease",
            }}
            className={Styles.accordion__content}
          >
            <div
              className={Styles.accordion__text}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <Stack
              className={Styles.accordion_contact_btn}
              onClick={() => {
                handleOpen();
              }}
            >
              {"->"}
            </Stack>
          </div>
        </div>
      </Box>
      <ContactUsModal open={open} handleClose={handleClose} />
    </>
  );
};

export default FormulationAccordion;
